import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import LandscapeIcon from '@material-ui/icons/LandscapeOutlined'
import BookmarksIcon from '@material-ui/icons/BookmarksOutlined'
import Drawer from '@material-ui/core/Drawer'
import Tooltip from '@material-ui/core/Tooltip'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Theme } from '../theme/theme'
import { DrawerType } from '../UI'
import CcLogoFullIcon from '../../icons/CcLogoIcon'

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: theme.palette.primary.light,
  },
  drawerPaper: {
    zIndex: 10,
    width: '56px',
    padding: theme.spacing(1.5, 1),
    [theme.breakpoints.down('xs')]: {
      background: 'none',
      border: 'none',
      width: '100%',
      flexDirection: 'row',
      height: 'auto',
    },
  },
  iconButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down('xs')]: {
      color: 'rgba(0,0,0,0.8)',
      margin: theme.spacing(0),
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  ccLogoWrapper: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  ccLogo: {
    flex: 0,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 0, 1, 0),
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  iconSpan: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  },
  badge: {
    top: 2,
    right: 8,
    height: 8,
    width: 8,
    background: theme.branding.connect.main,
    borderRadius: '50%',
    position: 'absolute',
  },
}))

interface SideBarIconButtonProps {
  icon: ReactNode
  label: string
  onClick?: () => void
}

const SideBarIconButton: React.FC<SideBarIconButtonProps> = ({
  icon,
  label,
  onClick,
  ...props
}) => {
  const classes = useStyles()
  const maxWidth600 = useMediaQuery('(max-width:600px)')
  return (
    <Tooltip title={label} placement={maxWidth600 ? 'bottom' : 'right'}>
      <IconButton
        onClick={onClick}
        disableFocusRipple
        disableTouchRipple
        aria-label={label}
        className={classes.iconButton}
        {...props}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

const Badge: React.FC = () => {
  const classes = useStyles()
  return <div className={classes.badge} />
}

export interface SideBarProps {
  setShowDrawer: (bool: boolean) => void
  setShowMenuDrawer: (bool: boolean) => void
  setShowInfo: (bool: boolean) => void
  setCurrentDrawer: (drawer: DrawerType) => void
  showSearchBadge: boolean
}

const SideBar: React.FC<SideBarProps> = ({
  setShowDrawer,
  setCurrentDrawer,
  setShowMenuDrawer,
  setShowInfo,
  showSearchBadge,
}) => {
  const classes = useStyles()

  const handleOpenDrawer = (drawer: DrawerType) => {
    setCurrentDrawer(drawer)
    setShowDrawer(true)
  }

  return (
    <Drawer
      variant={'permanent'}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}>
      <div className={classes.toolbar}>
        <SideBarIconButton
          label={'Menu'}
          icon={<Menu fontSize={'inherit'} />}
          onClick={() => {
            setShowDrawer(false)
            setShowMenuDrawer(true)
          }}
        />
        <span className={classes.iconSpan}>
          <div style={{ position: 'relative' }}>
            {showSearchBadge ? <Badge /> : null}
            <SideBarIconButton
              label={'Search Strains'}
              icon={<SearchIcon fontSize={'inherit'} />}
              onClick={() => handleOpenDrawer('search')}
            />
          </div>
          <SideBarIconButton
            label={'Bookmarks'}
            icon={<BookmarksIcon fontSize={'inherit'} />}
            onClick={() => handleOpenDrawer('bookmarks')}
          />
          <SideBarIconButton
            label={'Show Regions'}
            icon={<LandscapeIcon fontSize={'inherit'} />}
            onClick={() => handleOpenDrawer('regions')}
          />
          <SideBarIconButton
            label={'Info'}
            icon={<InfoIcon fontSize={'inherit'} />}
            onClick={() => setShowInfo(true)}
          />
        </span>
      </div>
      <div className={classes.ccLogoWrapper}>
        <CcLogoFullIcon className={classes.ccLogo} color={'black'} size={40} />
      </div>
    </Drawer>
  )
}

export default SideBar
