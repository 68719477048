import React, { useEffect, useState, useMemo } from 'react'
import * as _ from 'lodash'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useSnackbar } from 'notistack'
import classnames from 'classnames'
import Visualization, { VisualizationProps } from '../visualization/Visualization'
import ActionButtons from './components/ActionButtons'
import ConnectDot, { CategoryInfo, LinePair } from '../models/ConnectDot'
import ConnectRegion from '../models/ConnectRegion'
import StrainCard from './components/StrainCard'
import RegionCard from './components/RegionCard'
import SideBar from './components/SideBar'
import MenuDrawer from './components/MenuDrawer'
import SearchDrawer, { SearchDrawerProps } from './components/SearchDrawer'
import BookmarksDrawer from './components/BookmarksDrawer'
import { Theme } from './theme/theme'
import SwipeableCards from './components/SwipeableCards'
import RegionDrawer from './components/RegionDrawer'
import Info from './components/Info'
import Swipeable from './components/Swipeable'
import CCAnalytics from '../utils/CCAnalytics'
import CardStack from './components/CardStack'

const highlightColor = '#0067E6'
const drawerWidth = 348
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '0px',
    left: '0px',
    display: 'flex',
  },
  compareCarouselWrapper: {
    position: 'relative',
    right: 'auto',
    maxWidth: '328px',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    zIndex: 50,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 1, 0),
      width: '100%',
      maxWidth: '100%',
    },
  },
  strainCardWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '328px',
    margin: theme.spacing(1),
    zIndex: 50,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      top: 'auto',
      right: 'auto',
      bottom: 56,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: theme.spacing(0, 0, 1, 0),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '336px',
      margin: theme.spacing(1, 0),
    },
  },
  flexRowReverse: {
    flexDirection: 'row-reverse',
  },
  drawer: {
    display: 'flex',
    flexShrink: 0,
    zIndex: 9,
    [theme.breakpoints.down('xs')]: {
      zIndex: 100,
    },
  },
  drawerHeader: {
    position: 'absolute',
    height: 64,
    flexShrink: 0,
    zIndex: 10,
    backgroundColor: theme.branding.greyPalette.light,
  },
  drawerPaper: {
    marginLeft: theme.spacing(7),
    width: drawerWidth,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexGrow: 1,
    zIndex: 2,
    height: '100%',
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}))

export interface StateDot {
  id: number
  color: string
  strain: string
  label: string
  cannabinoid: string
  taxonomy: string
  method: string
  locale: string
  composite: boolean
  available: boolean
  badgeToken: string
  galleryUrl: string
  inventoryKey?: string
  regionId?: number
  position: {
    x: number
    y: number
    z: number
  }
}

export type DrawerType = 'search' | 'bookmarks' | 'regions'

export interface UIProps {
  className?: string
  dots?: ConnectDot[]
  regions?: ConnectRegion[]
  locales?: string[]
  categoryInfo: CategoryInfo
  onVisualizationProgress: (progress: number) => void
}

const UI: React.FC<UIProps> = ({
  dots,
  regions,
  locales,
  categoryInfo,
  onVisualizationProgress,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const maxWidth600 = useMediaQuery('(max-width:600px)')
  const tabletSize = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const maxHeight1050 = useMediaQuery('(max-height:1050px)')

  const [stateDots, setStateDots] = useState<StateDot[]>([])
  const [animate] = useState<VisualizationProps['animate']>(true)
  const [selectedRegions, setSelectedRegions] = useState<number[]>([])
  const [selected, setSelected] = useState<number[]>([])
  const [cards, setCards] = useState<number[]>([])
  const [compareCardIndex, setCompareCardIndex] = useState<number>(0)
  const [compares, setCompares] = useState<number[]>([])

  const storedBookmarks = localStorage.getItem('bookmarks')
  let initialBookmarks: number[] = []
  if (storedBookmarks && typeof JSON.parse(storedBookmarks) === 'object') {
    initialBookmarks = JSON.parse(storedBookmarks)
  }
  const [bookmarks, setBookmarks] = useState<number[]>(initialBookmarks)
  const [mobileExpandCardOpen, setMobileExpandCardOpen] = useState(false)
  const [showCompareCards, setShowCompareCards] = useState(false)

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchResults, setSearchResults] = useState<StateDot[]>()

  const [colorOption, setColorOption] = useState<'c' | 'ish' | 'method'>()

  const [activeDots, setActiveDots] = useState<number[]>([])
  const [focusDots, setFocusDots] = useState<number[]>([])
  const [activeRegions, setActiveRegions] = useState<number[]>([])
  const [activeLines, setActiveLines] = useState<VisualizationProps['activeLines']>([])
  const [availableDots, setAvailableDots] = useState<VisualizationProps['availableDots']>([])
  const [highlightDots, setHighlightDots] = useState<{ id: number; color: string }[]>([])
  const [dotColors, setDotColors] = useState<VisualizationProps['dotColors']>({})

  const hasShownInfo = localStorage.getItem('hasShownInfo')
  const [showInfo, setShowInfo] = useState(!hasShownInfo)
  localStorage.setItem('hasShownInfo', '1')

  const { enqueueSnackbar } = useSnackbar()
  const [showDrawer, setShowDrawer] = useState(false)
  const [currentDrawer, setCurrentDrawer] = useState<DrawerType>()
  const [showMenuDrawer, setShowMenuDrawer] = useState(false)

  const mapLocaleFilters = () => {
    const localeFilters: { [key: string]: { label: string; value: boolean } } = {}
    if (locales) {
      _.forEach(locales, locale => {
        localeFilters[locale.toLowerCase()] = { label: locale, value: false }
      })
    }
    return localeFilters
  }

  const initialFilters = {
    other: {
      composite: { label: 'Show Composite Strains', value: true },
    },
    taxonomy: {
      all: { label: 'All', value: true },
      indica: { label: 'Indica', value: false },
      sativa: { label: 'Sativa', value: false },
      hybrid: { label: 'Hybrid', value: false },
      other: { label: 'Other', value: false },
    },
    method: {
      all: { label: 'All', value: true },
      greenhouse: { label: 'Greenhouse', value: false },
      indoor: { label: 'Indoor', value: false },
      lightDeprivation: { label: 'Light Deprivation', value: false },
      mixedLight: { label: 'Mixed Light', value: false },
      nationwide: { label: 'Nationwide', value: false },
      other: { label: 'Other', value: false },
      outdoor: { label: 'Outdoor', value: false },
      unknown: { label: 'Unknown', value: false },
    },
    locales: {
      all: { label: 'All', value: true },
      ...mapLocaleFilters(),
    },
  }

  const [filters, setFilters] = useState<SearchDrawerProps['filters']>(initialFilters)

  const mapDotsToIds = (data: StateDot[]) => _.map(data, dot => dot.id)
  const getStateDotFromId = (id: number) => stateDots[stateDots.findIndex(dot => id === dot.id)]
  const mapIdsToDots = (ids: number[]) => _.map(ids, getStateDotFromId)

  const sortByDistance = (unsorted: StateDot[], centerDot: StateDot): StateDot[] => {
    const distanceSquared = (a: StateDot, target: StateDot) => {
      return (
        (a.position.x - target.position.x) ** 2 +
        (a.position.y - target.position.y) ** 2 +
        (a.position.z - target.position.z) ** 2
      )
    }
    // sort by distance to selected
    return unsorted.sort((a, b) => distanceSquared(a, centerDot) - distanceSquared(b, centerDot))
  }

  const handleSetHighlightDots = () => {
    const highlights: { id: number; color: string }[] = []
    if (selected) {
      selected.forEach(id => {
        if (activeDots && activeDots.includes(id)) {
          highlights.push({ id, color: '#333' })
        }
      })
    }
    if (compares) {
      compares.forEach(id => {
        if (!selected.includes(id)) {
          if (activeDots && activeDots.includes(id)) {
            highlights.push({ id, color: highlightColor })
          }
        }
      })
    }
    setHighlightDots(highlights)
  }

  const selectDotForExplore = (dotId: number) => {
    CCAnalytics.track('SelectDotForExplore', getStateDotFromId(dotId))
    const newCards = mapDotsToIds(
      sortByDistance(mapIdsToDots(activeDots), getStateDotFromId(dotId))
    ).slice(0, 10)
    setCards(newCards)
    setCompareCardIndex(newCards.findIndex(card => card === dotId))
    setCompares([])
    setSelected([dotId])
    setFocusDots(newCards)
  }

  const compareDot = (dotId: number) => {
    CCAnalytics.track('CompareDot', getStateDotFromId(dotId))
    setCompares([dotId])
    const cardIndex = cards.findIndex(card => card === dotId)
    if (cardIndex !== -1) {
      setCompareCardIndex(cardIndex)
    } else {
      setCards([dotId])
      setCompareCardIndex(0)
      setSelected([dotId])
    }
  }

  const onClickDot: VisualizationProps['onClickDot'] = dot => {
    compareDot(dot.id)
  }

  // set certain colors as inactive when colorOption changes
  useEffect(() => {
    if (dots && categoryInfo) {
      const colors: { [key: number]: string } = {}
      const updateStateDots = _.clone(stateDots)
      dots.forEach((dot, index) => {
        let colorString = '#ccc'
        switch (colorOption) {
          case 'c':
            if (dot.cannabinoid !== undefined) {
              colorString = categoryInfo.cannabinoidInfo[dot.cannabinoid].color || '#f00'
            }
            break
          case 'ish':
            colorString = categoryInfo.indicaSativaInfo[dot.indicaSativa].color || '#f00'
            break
          case 'method':
            colorString = categoryInfo.methodInfo[dot.method].color || '#f00'
            break
          default:
            colorString = '#ccc'
        }
        if (colorString) colors[dot.id] = colorString
        if (updateStateDots[index]) updateStateDots[index].color = colorString
      })
      setStateDots(updateStateDots)
      setDotColors(colors)
    }
  }, [dots, colorOption, categoryInfo])

  useEffect(() => {
    if (dots && categoryInfo) {
      const newStateDots: StateDot[] = []
      dots.forEach(dot => {
        const newDot: StateDot = {
          id: dot.id,
          color: '#ccc',
          strain: dot.strain,
          label: dot.publicLabel,
          taxonomy: categoryInfo.indicaSativaInfo[dot.indicaSativa].label,
          method: categoryInfo.methodInfo[dot.method].label,
          cannabinoid:
            dot.cannabinoid !== undefined && dot.cannabinoid !== null
              ? categoryInfo.cannabinoidInfo[dot.cannabinoid].label
              : 'Uncategorized',
          locale: dot.locale,
          composite: dot.group === 0,
          available: dot.available,
          badgeToken: dot.badgeToken,
          galleryUrl: dot.galleryUrl,
          inventoryKey: dot.inventoryKey,
          regionId: dot.regionId,
          position: { x: dot.position.x, y: dot.position.y, z: dot.position.z },
        }
        newStateDots.push(newDot)
      })

      setStateDots(newStateDots)
      setActiveDots(mapDotsToIds(newStateDots))
      setColorOption('c')
    }
  }, [dots, categoryInfo])

  const onDblClickDot: VisualizationProps['onDblClickDot'] = dot => {
    selectDotForExplore(dot.id)
  }

  useEffect(() => {
    if (categoryInfo && stateDots.length && locales) {
      const active = stateDots
        .filter(dot => dot.strain.toLowerCase().includes(searchQuery.toLowerCase()))
        .filter(dot => filters.locales.all.value || filters.locales[dot.locale.toLowerCase()].value)
        .filter(
          dot =>
            filters.taxonomy.all.value ||
            (categoryInfo && filters.taxonomy[_.camelCase(dot.taxonomy)].value) ||
            false
        )
        .filter(
          dot =>
            filters.method.all.value ||
            (categoryInfo && filters.method[_.camelCase(dot.method)].value) ||
            false
        )
        .filter(dot => filters.other.composite.value || !dot.composite)

      mapIdsToDots(selected).forEach(selectedDot => {
        if (!active.includes(selectedDot)) {
          active.push(selectedDot)
        }
      })
      if (compares) {
        mapIdsToDots(compares).forEach(comparesDot => {
          if (!active.includes(comparesDot)) {
            active.push(comparesDot)
          }
        })
      }

      if (activeLines) {
        const newLines = activeLines.filter(
          lines =>
            mapDotsToIds(active).includes(lines.startId) &&
            mapDotsToIds(active).includes(lines.endId)
        )
        setActiveLines(newLines)
      }

      setCards(cards.filter(card => mapDotsToIds(active).includes(card)))

      setSearchResults(active)

      setActiveDots(mapDotsToIds(active))

      setFocusDots(mapDotsToIds(active))

      setAvailableDots(mapDotsToIds(active.filter((dot: StateDot) => dot.available)))

      setActiveRegions([])
    }
  }, [stateDots, filters, searchQuery])

  useEffect(() => {
    if (regions && selectedRegions) {
      setActiveRegions(selectedRegions)
    }
  }, [selectedRegions])

  useEffect(() => {
    localStorage.setItem('bookmarks', JSON.stringify(bookmarks))
  }, [bookmarks])

  useEffect(() => {
    const activeLinePairs: LinePair[] = []
    if (cards.length) {
      if (selected) {
        cards.forEach(cardId =>
          activeLinePairs.push({ startId: selected[0], endId: cardId, color: 'black' })
        )
        setActiveLines(activeLinePairs)
      }
    }
    if (compares) {
      if (selected) {
        compares.forEach(compareId =>
          activeLinePairs.push({ startId: selected[0], endId: compareId, color: highlightColor })
        )
      }
    }
    setActiveLines(activeLinePairs)

    setShowCompareCards(compares.length > 0 || selected.length > 0)
    handleSetHighlightDots()
  }, [compares, selected])

  const onShowNearestAvailable = (id: number) => {
    CCAnalytics.track('ShowNearestAvailable', getStateDotFromId(id))
    if (stateDots) {
      const filteredAvailable = stateDots
        .filter(dot => filters.locales.all.value || filters.locales[dot.locale.toLowerCase()].value)
        .filter(
          dot =>
            filters.taxonomy.all.value ||
            (categoryInfo && filters.taxonomy[_.camelCase(dot.taxonomy)].value) ||
            false
        )
        .filter(
          dot =>
            filters.method.all.value ||
            (categoryInfo && filters.method[_.camelCase(dot.method)].value) ||
            false
        )
        .filter(dot => filters.other.composite.value || !dot.composite)
        .filter(dot => dot.available || dot.id === id)

      const centerDot = getStateDotFromId(id)

      setSelected([id])

      if (centerDot) {
        const distanceSquared = (a: StateDot, target: StateDot) => {
          return (
            (a.position.x - target.position.x) ** 2 +
            (a.position.y - target.position.y) ** 2 +
            (a.position.z - target.position.z) ** 2
          )
        }
        // sort by distance to selected
        const sortedAvailable = filteredAvailable
          .sort((a, b) => distanceSquared(a, centerDot) - distanceSquared(b, centerDot))
          .slice(0, 10)

        const activeLinePairs: LinePair[] = []
        sortedAvailable.forEach(dot => {
          if (dot.id !== centerDot.id) {
            activeLinePairs.push({ startId: centerDot.id, endId: dot.id, color: '#333' })
          }
        })
        setActiveLines(activeLinePairs)

        setSearchResults(sortedAvailable)
        setActiveDots(mapDotsToIds(sortedAvailable))
        setFocusDots(mapDotsToIds(sortedAvailable))
        setCards(mapDotsToIds(sortedAvailable))
        if (sortedAvailable.length > 1) {
          setCompares([sortedAvailable[1].id])
          setCompareCardIndex(1)
        }
      }
    }
  }

  const onClickSearchResult = (id: number) => {
    selectDotForExplore(id)
    setShowDrawer(false)
  }

  const onMouseEnterSearchResult = (id: number) => {
    compareDot(id)
  }

  const onChangeSwipeableCard = (index: number, indexLatest: number) => {
    let newIndex = index

    // prevents switching past visible cards on desktop
    if (!maxWidth600 && !tabletSize && cards[index] === selected[0]) {
      newIndex = indexLatest
    }

    setCompareCardIndex(newIndex)

    if (cards[newIndex] && compareCardIndex !== newIndex) {
      setCompares([cards[newIndex]])
    }
  }

  const handleSetBookmark = (dot: StateDot) => {
    if (_.indexOf(bookmarks, dot.id) > -1) {
      CCAnalytics.track('ClearBookmark', dot)
      setBookmarks(_.filter(bookmarks, id => id !== dot.id))
      enqueueSnackbar('Bookmark Removed')
    } else {
      CCAnalytics.track('AddBookmark', dot)
      setBookmarks(_.union(bookmarks, [dot.id]))
      enqueueSnackbar('Bookmark Added')
    }
  }

  // i'll clean this up, but for some reason the swipeable library is typed to require an object vs string (className) - @harry
  const containerStyles = useMemo(() => {
    return { width: '100%', padding: theme.spacing(0, 4) }
  }, [])
  const slideStyles = useMemo(() => {
    return { padding: theme.spacing(0, 1, 0.5, 1), display: 'flex', justifyContent: 'center' }
  }, [])

  return (
    <div id={'UI'} className={classes.root}>
      <SideBar
        setShowDrawer={setShowDrawer}
        setCurrentDrawer={setCurrentDrawer}
        setShowMenuDrawer={setShowMenuDrawer}
        setShowInfo={setShowInfo}
        showSearchBadge={searchQuery.length > 0}
      />

      <MenuDrawer
        open={showMenuDrawer}
        setShowDrawer={setShowDrawer}
        setCurrentDrawer={setCurrentDrawer}
        setShowMenuDrawer={setShowMenuDrawer}
        setShowInfo={setShowInfo}
      />

      <Drawer
        className={classes.drawer}
        anchor={'left'}
        variant={'persistent'}
        open={showDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        {currentDrawer === 'search' && (
          <SearchDrawer
            dots={stateDots}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onCloseDrawer={() => setShowDrawer(false)}
            filters={filters}
            setFilters={setFilters}
            searchResults={searchResults}
            onClickSearchResult={onClickSearchResult}
            onMouseEnterSearchResult={onMouseEnterSearchResult}
          />
        )}
        {currentDrawer === 'bookmarks' && (
          <BookmarksDrawer
            onCloseDrawer={() => {
              setShowDrawer(false)
            }}
            onMouseEnterBookmarkItem={onMouseEnterSearchResult}
            onMouseLeaveBookmarkItem={() => setCompares([])}
            onClickBookmarkItem={onClickSearchResult}
            bookmarks={mapIdsToDots(bookmarks)}
          />
        )}
        {currentDrawer === 'regions' && (
          <RegionDrawer
            regions={regions}
            onCloseDrawer={() => {
              setShowDrawer(false)
            }}
            setSelectedRegions={setSelectedRegions}
            setFocusRegions={regionIds => {
              if (stateDots) {
                const dotsInRegions = stateDots.filter(
                  dot => dot.regionId && regionIds.includes(dot.regionId)
                )
                setFocusDots(mapDotsToIds(dotsInRegions))
              }
            }}
          />
        )}
      </Drawer>
      <Info open={showInfo} handleClose={() => setShowInfo(false)} />
      <div
        className={classnames(
          classes.strainCardWrapper,
          maxHeight1050 ? classes.flexRowReverse : undefined
        )}>
        {stateDots &&
          !tabletSize &&
          !maxWidth600 &&
          selectedRegions.length === 0 &&
          selected &&
          selected.length > 0 && (
            <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'center' }}>
              {selected.map(selectDotId => {
                const dot = getStateDotFromId(selectDotId)
                return dot ? (
                  <StrainCard
                    key={dot.id}
                    id={dot.id}
                    handleClose={() => {
                      setCompares([])
                      setSelected([])
                    }}
                    available={dot.available}
                    badgeToken={dot.badgeToken}
                    galleryQuery={dot.galleryUrl}
                    galleryToken={dot.inventoryKey}
                    headerColor={dot.color}
                    cannabinoidClass={dot.cannabinoid}
                    taxonomy={dot.taxonomy}
                    composite={dot.composite}
                    locale={dot.locale}
                    strain={dot.strain}
                    setNearestAvailable={() => {
                      onShowNearestAvailable(dot.id)
                    }}
                    isBookmark={_.indexOf(bookmarks, dot.id) > -1}
                    setBookmark={() => handleSetBookmark(dot)}
                  />
                ) : null
              })}
            </div>
          )}

        {showCompareCards &&
          tabletSize &&
          !maxWidth600 &&
          cards &&
          cards.length > 0 &&
          !(cards[compareCardIndex] === selected[0] && !maxWidth600 && !tabletSize) &&
          selectedRegions.length === 0 && (
            <CardStack onChange={onChangeSwipeableCard} cardIndex={compareCardIndex}>
              {cards.map(dotId => {
                const dot = getStateDotFromId(dotId)
                return (
                  <StrainCard
                    key={dot.id}
                    id={dot.id}
                    handleClose={() => {
                      setShowCompareCards(false)
                      setMobileExpandCardOpen(false)
                    }}
                    setExpand={setMobileExpandCardOpen}
                    expand={mobileExpandCardOpen}
                    available={dot.available}
                    badgeToken={dot.badgeToken}
                    galleryQuery={dot.galleryUrl}
                    galleryToken={dot.inventoryKey}
                    headerColor={dot.color}
                    cannabinoidClass={dot.cannabinoid}
                    taxonomy={dot.taxonomy}
                    composite={dot.composite}
                    locale={dot.locale}
                    strain={dot.strain}
                    setNearestAvailable={value => {
                      if (value) {
                        onShowNearestAvailable(dot.id)
                      }
                    }}
                    isBookmark={_.indexOf(bookmarks, dot.id) > -1}
                    setBookmark={() => handleSetBookmark(dot)}
                  />
                )
              })}
            </CardStack>
          )}
        <Swipeable
          onSwipe={() => {
            setShowCompareCards(false)
            setMobileExpandCardOpen(false)
          }}
          showCards={showCompareCards}
          className={classes.compareCarouselWrapper}
          enabled={maxWidth600}>
          {showCompareCards &&
            !tabletSize &&
            cards &&
            cards.length > 0 &&
            !(cards[compareCardIndex] === selected[0] && !maxWidth600 && !tabletSize) &&
            selectedRegions.length === 0 && (
              <SwipeableCards
                onChangeIndex={onChangeSwipeableCard}
                index={compareCardIndex}
                animate={maxWidth600}
                containerStyle={maxWidth600 ? containerStyles : { width: '100%' }}
                slideStyle={
                  maxWidth600
                    ? slideStyles
                    : {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: theme.spacing(0, 0, 0.5, 0),
                      }
                }>
                {cards.map((dotId, index) => {
                  if (Math.abs(compareCardIndex - index) <= 2) {
                    const dot = getStateDotFromId(dotId)
                    return (
                      <StrainCard
                        key={dot.id}
                        id={dot.id}
                        handleClose={() => {
                          setShowCompareCards(false)
                          setMobileExpandCardOpen(false)
                        }}
                        setExpand={setMobileExpandCardOpen}
                        expand={mobileExpandCardOpen}
                        available={dot.available}
                        badgeToken={dot.badgeToken}
                        galleryQuery={dot.galleryUrl}
                        galleryToken={dot.inventoryKey}
                        headerColor={dot.color}
                        cannabinoidClass={dot.cannabinoid}
                        taxonomy={dot.taxonomy}
                        composite={dot.composite}
                        locale={dot.locale}
                        strain={dot.strain}
                        setNearestAvailable={value => {
                          if (value) {
                            onShowNearestAvailable(dot.id)
                          }
                        }}
                        isBookmark={_.indexOf(bookmarks, dot.id) > -1}
                        setBookmark={() => handleSetBookmark(dot)}
                      />
                    )
                  }
                  return <div key={dotId} />
                })}
              </SwipeableCards>
            )}
        </Swipeable>
      </div>
      <div className={classes.strainCardWrapper}>
        {regions &&
          selectedRegions &&
          selectedRegions.map(selectedRegionId => {
            const selectedRegion = regions.find(
              region => selectedRegionId === region.optics_cluster
            )
            return (
              selectedRegion && (
                <RegionCard
                  key={selectedRegion.optics_cluster}
                  id={selectedRegion.optics_cluster}
                  handleClose={() => {
                    setSelectedRegions([])
                    setFocusDots(activeDots)
                  }}
                  badgeUrl={selectedRegion.badge_url}
                  cannabinoidClasses={selectedRegion.cannabinoid_classes}
                  classification={selectedRegion.classification}
                  bestKnownGlobalStrains={selectedRegion.best_known_global_strains}
                  mostCentralNationalStrain={selectedRegion.most_central_national_strain}
                  mostCommonPrivateStrains={selectedRegion.most_common_private_strain}
                />
              )
            )
          })}
      </div>

      <ActionButtons
        colorOption={colorOption || 'c'}
        setColorOption={setColorOption}
        categoryInfo={categoryInfo}
        filtered={
          (activeDots && stateDots && activeDots.length !== stateDots.length) ||
          selected.length > 0 ||
          false
        }
        onClickClearFilters={() => {
          setFilters(initialFilters)
          setSearchQuery('')
          setSelected([])
          setCompares([])
        }}
      />
      <Visualization
        className={!maxWidth600 && showDrawer ? classes.contentShift : classes.content}
        dots={dots}
        regions={regions}
        animate={animate}
        onLoadProgress={onVisualizationProgress}
        onClickDot={onClickDot}
        onDblClickDot={onDblClickDot}
        activeDots={activeDots}
        focusDots={focusDots}
        activeRegions={activeRegions}
        activeLines={activeLines}
        availableDots={availableDots}
        highlightDots={highlightDots}
        dotColors={dotColors}
      />
    </div>
  )
}

export default UI
