import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import classnames from 'classnames'
import RegionLegendIcon from './RegionLegendIcon'
import { Theme } from '../theme/theme'
import ConnectRegion from '../../models/ConnectRegion'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  drawerHeader: {
    height: 56,
    flexShrink: 0,
    zIndex: 10,
    backgroundColor: theme.branding.greyPalette.light,
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  listItemSecondaryText: {
    fontSize: 12,
  },
  searchResults: {
    overflowY: 'scroll',
  },
  resultIcon: {
    margin: theme.spacing(0, 2),
  },
  table: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    borderBottom: 'none',
  },
  tableBody: {
    flexShrink: 0,
    overflowY: 'auto',
    width: '100%',
  },
  tableRow: {
    width: '100%',
    padding: 0,
    '&:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },
  },
  tableCell: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  tableFooter: {
    width: '100%',
    zIndex: 10,
    alignSelf: 'flex-end',
    flex: 0,
    borderTop: '0.5px solid rgba(0,0,0,0.12)',
  },
  tableWrapper: {
    flex: 1,
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  tableActions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
  closeButton: {
    padding: theme.spacing(0, 0.5, 0, 0.25),
  },
  iconButton: {
    '&:hover': {
      background: 'transparent',
    },
  },
  title: {
    flexGrow: 1,
  },
  softBlack: {
    color: theme.branding.greyPalette.soft,
  },
  bold: {
    fontWeight: 800,
    letterSpacing: '0.08rem',
  },
  regionInfo: {
    padding: theme.spacing(2, 1, 1, 1),
  },
}))

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void
}

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
}: TablePaginationActionsProps) => {
  const classes = useStyles()

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.tableActions}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={'First Page'}>
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={'Previous Page'}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={'Next Page'}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={'Last Page'}>
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export interface RegionDrawerProps {
  onCloseDrawer: () => void
  regions?: ConnectRegion[]
  setSelectedRegions: (regionIds: number[]) => void
  setFocusRegions: (regionIds: number[]) => void
}

const RegionDrawer: React.FC<RegionDrawerProps> = ({
  regions,
  onCloseDrawer,
  setSelectedRegions,
  setFocusRegions,
}) => {
  const regionsToCompare = regions || []
  const classes = useStyles()
  const rows = regionsToCompare !== undefined ? regionsToCompare : []
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <div className={classes.root}>
      <div className={classes.drawerHeader}>
        <AppBar
          style={{ borderBottom: '0.5px solid rgba(0,0,0,0.12)' }}
          elevation={0}
          position={'static'}
          color={'default'}>
          <Toolbar>
            <Typography
              variant={'subtitle1'}
              className={classnames(classes.title, classes.softBlack, classes.bold)}>
              Strain Regions
            </Typography>
            <IconButton
              disableFocusRipple
              disableTouchRipple
              className={classnames(classes.iconButton, classes.closeButton)}
              onClick={() => onCloseDrawer()}
              aria-label={'Close'}>
              <KeyboardArrowLeft fontSize={'inherit'} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.regionInfo}>
        <Typography variant={'caption'}>
          Regions are discrete areas of the cluster that have common characteristics. Regions are a
          convenient way to navigate and explore the Cluster.
        </Typography>
      </div>
      <Divider />
      <div className={classes.tableWrapper}>
        <div style={{ overflow: 'auto', flex: 1 }}>
          <Table className={classes.table}>
            <TableBody className={classes.tableBody}>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((region, index) => (
                  <TableRow className={classes.tableRow} key={region.optics_cluster}>
                    <TableCell className={classes.tableCell} component={'td'} scope={'row'}>
                      <ListItem
                        onClick={() => {
                          setSelectedRegions([region.optics_cluster])
                          setFocusRegions([region.optics_cluster])
                          onCloseDrawer()
                        }}
                        onMouseEnter={() => {
                          setSelectedRegions([region.optics_cluster])
                          setFocusRegions([])
                        }}
                        className={classes.listItem}
                        button>
                        <Container
                          style={{
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}>
                          <RegionLegendIcon
                            className={classes.resultIcon}
                            badgeUrl={region.badge_low}
                            size={64}
                          />
                          <Container
                            style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'h2'}>{`Region ${index + 1}`}</Typography>
                          </Container>
                        </Container>
                      </ListItem>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} style={{ borderBottom: 'none' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <Table className={classes.tableFooter}>
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={0}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  )
}

export default RegionDrawer
