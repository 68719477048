import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import legendMask from '../../../assets/icons/legend_mask.png'

export interface StrainLegendIconProps {
  color: string
  size?: number
  spacing?: number
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '50%',
  },
}))

const StrainLegendIcon: React.FC<StrainLegendIconProps> = ({ color, size = 32, className }) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(classes.root, className)}
      style={{ background: color, height: size, width: size }}>
      <img alt={'legend'} src={legendMask} style={{ opacity: 0.5, height: size, width: size }} />
    </div>
  )
}

export default StrainLegendIcon
