import React from 'react'
import useTheme from '@material-ui/core/styles/useTheme'
import { version } from '../../../package.json'

const VersionLabel: React.FC = () => {
  const theme = useTheme()
  return (
    <span
      style={{
        position: 'absolute',
        bottom: '0px',
        right: '4px',
        zIndex: 100,
        color: 'rgba(0,0,0,0.2)',
        fontSize: theme.typography.pxToRem(12),
      }}>
      v{version}
    </span>
  )
}

export default VersionLabel
