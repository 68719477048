import React, { ReactNode } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft'
import ArrowRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
// import Button from '@material-ui/core/Button'
import { CSSProperties } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Collapse from '@material-ui/core/Collapse'
import Checkbox from '@material-ui/core/Checkbox'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import classnames from 'classnames'
import { Theme } from '../theme/theme'
import { useBadgeUrl, useWholesaleUrl } from '../../hooks'
import GlobeIcon from '../../icons/GlobeIcon'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 320,
    fontWeight: 'bold',
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
    },
    padding: theme.spacing(1, 1, 1, 2),
    userSelect: 'none',
  },
  title: {
    margin: theme.spacing(1, 0, 2, 0),
    fontSize: '1rem',
    letterSpacing: '0.1pt',
    lineHeight: '26px',
  },
  subheader: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(12),
  },
  media: {
    height: 0,
    paddingTop: '100%',
  },
  cardContent: {
    padding: theme.spacing(0.5),
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0.5, 0.5, 1),
  },
  bold: {
    fontWeight: 800,
    letterSpacing: '0.08rem',
  },
  white: {
    color: theme.palette.common.white,
  },
  closeIcon: {
    margin: theme.spacing(1, 1, 0, 0.5),
    padding: theme.spacing(0),
  },
  lockButton: {
    margin: theme.spacing(1, 0.5, 0, 1),
    padding: theme.spacing(0),
  },
  idNumber: {
    left: '2px',
    bottom: '2px',
    fontSize: '8px',
    color: '#999',
  },
  globeIcon: {
    margin: theme.spacing(0, 1),
  },
  hide: {
    display: 'none',
  },
}))

export interface StrainCardProps {
  style?: CSSProperties
  handleClose: () => void
  id: number
  available: boolean
  className?: string
  badgeToken: string
  galleryToken?: string
  galleryQuery?: string
  headerColor: string
  composite: boolean
  cannabinoidClass: string
  taxonomy?: string
  locale: string
  strain: string
  setNearestAvailable?: (bool: boolean) => void
  isBookmark: boolean
  setBookmark: () => void
  expand?: boolean
  setExpand?: (bool: boolean) => void
  changeCardStack?: (dir: string) => void
  showStackButtons?: boolean
  disableLeft?: boolean
  disableRight?: boolean
}

export interface StrainCardSubheaderProps
  extends Pick<StrainCardProps, 'taxonomy' | 'cannabinoidClass'> {
  locale: ReactNode
}

const StrainCardSubheader: React.FC<StrainCardSubheaderProps> = ({
  cannabinoidClass,
  taxonomy,
  locale,
}) => {
  const classes = useStyles()

  return (
    <Container className={classnames(classes.subheader, classes.white)}>
      <Typography variant={'caption'}>{cannabinoidClass}</Typography>
      <Typography variant={'caption'}>{taxonomy}</Typography>
      {locale}
    </Container>
  )
}

// interface StrainCardButtonProps {
//   url?: string
//   label: string
//   onClick?: () => void
// }

// const StrainCardButton: React.FC<StrainCardButtonProps> = ({ url, label, ...props }) => {
//   return (
//     <Button target={'_blank'} {...props} href={url || ''} size={'small'} variant={'contained'}>
//       {label}
//     </Button>
//   )
// }

const StrainCard: React.FC<StrainCardProps> = ({
  setNearestAvailable,
  handleClose,
  id,
  strain,
  available,
  badgeToken,
  galleryToken,
  galleryQuery,
  headerColor,
  cannabinoidClass,
  taxonomy,
  locale,
  composite,
  className,
  style,
  isBookmark,
  setBookmark,
  expand,
  setExpand,
  changeCardStack,
  disableLeft,
  disableRight,
  showStackButtons,
}) => {
  const classes = useStyles()
  const badgeUrl = useBadgeUrl(badgeToken)
  const gallerySearchUrl = galleryQuery ? useWholesaleUrl(galleryQuery) : null
  const galleryInventorylUrl = galleryToken
    ? useWholesaleUrl(`gallery/${galleryToken}/detail`)
    : null
  const theme = useTheme()
  const maxWidth600 = useMediaQuery('(max-width:600px)')
  const tabletSize = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const localeLabel = composite ? (
    <Typography variant={'caption'}>
      <GlobeIcon className={classes.globeIcon} size={11} color={'rgba(255, 255, 255, 0.84)'} />
      Composite
    </Typography>
  ) : (
    <Typography variant={'caption'}>{`${locale} Private`}</Typography>
  )

  // const strainButton = () => {
  //   let button = (
  //     <StrainCardButton
  //       onClick={
  //         setNearestAvailable
  //           ? () => {
  //               setNearestAvailable(true)
  //             }
  //           : undefined
  //       }
  //       label={'Show Nearest Available'}
  //     />
  //   )
  //   if (composite) {
  //     button = <StrainCardButton url={gallerySearchUrl || ''} label={'Search Wholesale Gallery'} />
  //   } else if (available) {
  //     button = (
  //       <StrainCardButton url={galleryInventorylUrl || ''} label={'Show in Wholesale Gallery'} />
  //     )
  //   }
  //   return button
  // }

  return (
    <Card
      style={style}
      elevation={tabletSize ? 3 : 1}
      className={classnames(classes.card, className)}>
      {maxWidth600 ? (
        <div
          style={{
            backgroundColor: headerColor,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '4px',
          }}>
          <div
            style={{
              backgroundColor: 'rgba(255,255,255,0.4)',
              width: '32px',
              height: '4px',
              borderRadius: '25px',
            }}
          />
        </div>
      ) : null}
      <CardHeader
        className={classes.header}
        style={{ backgroundColor: headerColor }}
        title={strain}
        titleTypographyProps={{
          variant: 'h6',
          className: classnames(classes.white, classes.title),
        }}
        onClick={maxWidth600 && setExpand ? () => setExpand(!expand) : undefined}
        action={
          !maxWidth600 && (
            <IconButton
              onClick={e => {
                e.stopPropagation()
                handleClose()
              }}
              className={classes.closeIcon}
              size={'small'}>
              <CloseIcon className={classnames(classes.white)} fontSize={'inherit'} />
            </IconButton>
          )
        }
        subheader={
          <StrainCardSubheader
            cannabinoidClass={cannabinoidClass}
            taxonomy={taxonomy}
            locale={localeLabel}
          />
        }
      />

      <CardContent className={classes.cardContent}>
        {maxWidth600 ? (
          <Collapse in={maxWidth600 && expand}>
            <CardMedia className={classnames(classes.media)} image={badgeUrl} title={'Imprint'} />
            <span className={classes.idNumber}>{id}</span>
          </Collapse>
        ) : (
          <CardMedia className={classnames(classes.media)} image={badgeUrl} title={'Imprint'} />
        )}
        {!maxWidth600 && <span className={classes.idNumber}>{id}</span>}
      </CardContent>
      <Divider className={maxWidth600 && !expand ? classes.hide : undefined} />
      <CardActions className={classes.cardFooter}>
        {/* {strainButton()} */}
        <Checkbox
          disableRipple
          disableTouchRipple
          disableFocusRipple
          icon={<BookmarkBorderIcon />}
          checkedIcon={<BookmarkIcon />}
          checked={isBookmark}
          onChange={setBookmark}
          value={'bookmark'}
          color={'default'}
        />
        {showStackButtons && changeCardStack && (
          <>
            <IconButton
              size={'small'}
              disabled={disableLeft}
              onClick={() => changeCardStack('left')}>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              size={'small'}
              disabled={disableRight}
              onClick={() => changeCardStack('right')}>
              <ArrowRightIcon />
            </IconButton>
          </>
        )}
      </CardActions>
    </Card>
  )
}

export default StrainCard
