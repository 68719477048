import { createMuiTheme } from '@material-ui/core/styles'

const primary = {
  light: '#6D7A85',
  main: '#2E4150',
  dark: '#233240',
  contrastText: '#FFF',
}

const secondary = {
  light: '#69CFBC',
  main: '#29BB9F',
  dark: '#1FAC8D',
  contrastText: '#FFF',
}

const greyPalette = {
  soft: 'rgba(0, 0, 0, 0.54)',
  medium: '#a1a2a4',
  main: '#444547',
  dark: '#1f2022',
  faint: '#f4f5f7',
  light: '#ebecee',
}

const connect = {
  light: '#4D99F0',
  medium: '#4080C9',
  main: '#4991E3',
  dark: '#3468A3',
  darker: '#203F63',
}

const theme = {
  ...createMuiTheme({
    typography: {
      fontFamily: ['"Open Sans"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
    },
    palette: {
      primary,
      secondary,
    },
    overrides: {
      MuiCheckbox: {
        root: {
          '&$checked': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        colorSecondary: {
          '&$checked': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          backgroundColor: connect.main,
          color: 'rgba(255,255,255, 0.94)',
          '&:hover': {
            backgroundColor: connect.dark,
            '@media (hover: none)': {
              backgroundColor: connect.main,
            },
          },
          '&:active': {
            backgroundColor: connect.main,
          },
        },
      },
      MuiFab: {
        label: {
          textTransform: 'none',
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: '#fff',
          color: 'rgba(0,0,0, 0.87)',
        },
      },
    },
  }),
  branding: {
    connect,
    actionsPalette: {
      success: 'green',
      error: 'red',
      warning: 'yellow',
      info: 'blue',
    },
    orderStatusPalette: {
      complete: '#61bb26',
      inProgress: '#f8ac59',
      pending: '#ed5565',
      cancelled: '#1f2022',
    },
    greyPalette,
  },
  text: {
    palette: {
      primary,
    },
    weight: {
      normal: 400,
      semiBold: 600,
      bold: 700,
    },
  },
}

export type Theme = typeof theme
export default theme
