import React, { MouseEvent } from 'react'
import MenuItem from '@material-ui/core/MenuItem'

interface Props {
  label?: string
  value?: string
  onClick: (e: MouseEvent, value?: string) => void
}

const CCMenuItem: React.FC<Props> = React.forwardRef(({ onClick, label, value }: Props, ref) => {
  const handleClick = (e: MouseEvent) => {
    return onClick(e, value)
  }

  return (
    <MenuItem innerRef={ref} onClick={handleClick}>
      {label}
    </MenuItem>
  )
})

export default CCMenuItem
