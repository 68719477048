import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

interface InfoProps {
  className?: string
  open: boolean
  handleClose: () => void
}

const Info: React.FC<InfoProps> = ({ open, handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog fullScreen={fullScreen} open={open} scroll={'paper'}>
      <DialogTitle id={'responsive-dialog-title'}>About Connect</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Connect helps you explore the different flower products in the legal cannabis marketplace.
          <br />
          <br />
          The colored spheres are called Dots. Each Dot represents a kind of flower on the market.
          Composite Dots represent all of the flower sold under a strain name, anywhere.
          <br />
          <br />
          Private Dots represent a single producer&apos;s flower sold under a strain name.
          <br />
          <br />
          The collection of all the Dots together is called the Cluster. The position of Dots in the
          Cluster shows how similar products are to each other, in terms of their expected
          chemistry. This means that consuming a bundle of flower from one Dot is likely to produce
          similar experience as consuming a bundle of flower from a nearby Dot.
          <br />
          <br />
          With Connect you can:
        </DialogContentText>
        <Typography color={'textSecondary'} component={'ul'}>
          <li>Use the search box to find the chemical profiles of your favorite strains.</li>
          <li>
            Compare flower you know and love with ones you&apos;ve never heard of to learn about new
            strains
          </li>
          <li>
            Use Regions to find Dots with the cannabinoids and terpenes you&apos;re looking for
          </li>
          <li>Learn how flower strains differ from each other</li>
          <li>Learn how producers&apos; versions of the same strain differ from each other</li>
          <li>Explore the different varieties of each strain available in different states</li>
          <li>
            See how the cannabis market is segmented into different groups, and find novel and
            unique combinations of terpenes and cannabinoids
          </li>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Info
