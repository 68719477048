import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'
import { ConvexBufferGeometry } from 'three/examples/jsm/geometries/ConvexGeometry'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

import * as TWEEN from '@tweenjs/tween.js'
import { Stats } from 'three-stats'
import {
  BackSide,
  Box3,
  BufferAttribute,
  BufferGeometry,
  Color,
  Geometry,
  HemisphereLight,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  MeshNormalMaterial,
  MeshPhongMaterial,
  PerspectiveCamera,
  // MeshToonMaterial,
  PointLight,
  Raycaster,
  Scene,
  SphereBufferGeometry,
  Vector2,
  Vector3,
  WebGLRenderer,
  VertexColors,
} from 'three'
import { SimpleRenderPass } from './SimpleRenderPass'
import ConnectDot, { LinePair } from '../models/ConnectDot'
import ConnectRegion from '../models/ConnectRegion'

class FpsWatch {
  private previousTime: number

  public deltaTime?: number

  public fpsSmoothed: number

  public constructor() {
    this.previousTime = Date.now()
    this.fpsSmoothed = 60
  }

  // eslint-disable-next-line  class-methods-use-this
  private lerp(v0: number, v1: number, t: number) {
    return v0 * (1 - t) + v1 * t
  }

  public update() {
    const now = Date.now()
    this.deltaTime = now - this.previousTime
    this.fpsSmoothed = this.lerp(this.fpsSmoothed, 1000 / this.deltaTime, 0.1)
    this.previousTime = now
  }
}

export interface ConnectEngineOptions {
  sceneSettings: {
    sizeAdjustment: number
    dotRadius: number
    tween: {
      duration: number
      easing: (t: number) => number
    }
    focusOffset: number
    focusScaleFactor: number
  }
  controlSettings: {
    rotateSpeed: number
    zoomSpeed: number
    panSpeed: number
    noZoom: boolean
    noPan: boolean
    dynamicDampingFactor: number
    keys: number[]
    maxDistance: number
    minDistance: number
  }
  lightSettings: {
    point: {
      distance: number
      decay: number
      color: number
      intensity: number
      position: {
        x: number
        y: number
        z: number
      }
    }
    hemi: {
      skyColor: number
      groundColor: number
      intensity: number
      position: {
        x: number
        y: number
        z: number
      }
      castShadow: boolean
      shadowCameraVisible: boolean
    }
  }
  rendererSettings: {
    backgroundColor: string
    antialias: boolean
    autoClear: boolean
    shadowMap: {
      enabled: boolean
    }
    shadowMapSoft: boolean
  }
  cameraSettings: {
    fov: number
    aspect: number
    near: number
    far: number
    zoom: number
    focalLength: number | null
    position: {
      x: number
      y: number
      z: number
    }
    pitch: number
    roll: number
    yaw: number
  }
}

interface RenderDot {
  position: Vector3
  distanceToCamera: number
  active: boolean
  highlight: boolean
  highlightColor: Color
  available: boolean
  connectDotId: number
  color: Color
}

interface RenderRegion {
  mesh: Mesh
  regionId: number
}

interface RenderLine {
  start: Vector3
  end: Vector3
  color: Color
}

interface PickingMesh extends Mesh {
  connectId: number
}

export type RenderQuality = 'fast' | 'good' | 'super'

export default class ConnectEngine {
  private doAnimate = true

  private renderQuality: RenderQuality

  private stats: Stats

  private renderer: WebGLRenderer

  private composer: EffectComposer

  private pickingMeshes: PickingMesh[] = []

  private camera: PerspectiveCamera

  private previousCameraPosition: Vector3

  public onMouseDown: (event: MouseEvent) => void

  public onMouseUp: (event: MouseEvent) => void

  public onMouseOverDot: (dot: ConnectDot) => void

  public onMouseEnterDot: (dot: ConnectDot) => void

  public onMouseLeaveDot: (dot: ConnectDot) => void

  public onDblClickDot: (dot: ConnectDot) => void

  public onClickDot: (dot: ConnectDot) => void

  public onMouseEnterRegion: (region: ConnectRegion) => void

  public onMouseLeaveRegion: (region: ConnectRegion) => void

  public onDblClickRegion: (region: ConnectRegion) => void

  public onClickRegion: (region: ConnectRegion) => void

  private highlightMesh: Mesh

  private tweens: { [key: string]: TWEEN.Tween }

  private availableColorValue: { x: number }

  private lods: {
    geometry: BufferGeometry
    sceneObject?: Mesh
    range: {
      fast: { min: number; max: number }
      good: { min: number; max: number }
      super: { min: number; max: number }
    }
    dots: RenderDot[]
    id?: number
  }[]

  private connectDots: { [id: number]: ConnectDot } = {}

  private connectRegions: { [id: number]: ConnectRegion } = {}

  private dotsById: { [id: number]: RenderDot } = {}

  private intersectedDotId?: number = undefined

  private intersectedRegionId?: number = undefined

  private renderDots: RenderDot[] = []

  private renderRegions: RenderRegion[] = []

  private renderLineObject: LineSegments

  private scene: Scene

  private regionScene: Scene

  private controls: TrackballControls

  public canvas: HTMLCanvasElement

  private mouse: Vector2

  private options: ConnectEngineOptions

  private fpsWatch: FpsWatch

  public constructor(
    canvas: HTMLCanvasElement,
    dots: ConnectDot[],
    regions: ConnectRegion[],
    options: ConnectEngineOptions
  ) {
    this.canvas = canvas

    this.options = options

    this.renderQuality = (localStorage.getItem('renderQuality') as RenderQuality) || 'fast'

    dots.forEach((connectDot: ConnectDot) => {
      this.connectDots[connectDot.id] = connectDot
      const renderDot = {
        position: new Vector3(
          connectDot.position.x,
          connectDot.position.y,
          connectDot.position.z
        ).multiplyScalar(options.sceneSettings.sizeAdjustment),
        active: true,
        available: false,
        connectDotId: connectDot.id,
        distanceToCamera: 0,
        highlight: false,
        highlightColor: new Color('#333'),
        color: new Color('#ff0000'),
      }
      this.renderDots.push(renderDot)
      this.dotsById[connectDot.id] = renderDot
    })

    this.tweens = {}

    this.availableColorValue = { x: -0.5 }
    this.tweens.availableColors = new TWEEN.Tween(this.availableColorValue)
      .to({ x: 0.6 }, 2000)
      .repeat(Infinity)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .yoyo(true)
      .start()

    this.lods = [
      {
        geometry: new SphereBufferGeometry(this.options.sceneSettings.dotRadius, 10, 10),
        range: {
          fast: { min: 50, max: Infinity },
          good: { min: 400, max: Infinity },
          super: { min: 4000, max: Infinity },
        },
        dots: [],
      },
      {
        geometry: new SphereBufferGeometry(this.options.sceneSettings.dotRadius, 12, 12),
        range: {
          fast: { min: 10, max: 50 },
          good: { min: 100, max: 400 },
          super: { min: 1000, max: 4000 },
        },
        dots: [],
      },
      {
        geometry: new SphereBufferGeometry(this.options.sceneSettings.dotRadius, 16, 16),
        range: {
          fast: { min: 4, max: 10 },
          good: { min: 50, max: 100 },
          super: { min: 200, max: 1000 },
        },
        dots: [],
      },
      {
        geometry: new SphereBufferGeometry(this.options.sceneSettings.dotRadius, 24, 24),
        range: {
          fast: { min: 0, max: 4 },
          good: { min: 0, max: 50 },
          super: { min: 0, max: 200 },
        },
        dots: [],
      },
    ]

    this.mouse = new Vector2(0, 0)

    this.stats = new Stats()
    // document.body.appendChild(this.stats.dom)

    this.scene = new Scene()
    this.highlightMesh = this.buildHighlight()
    this.scene.add(this.highlightMesh)
    this.renderDots.forEach(renderDot => {
      const pickingMesh = new Mesh(
        new SphereBufferGeometry(this.options.sceneSettings.dotRadius, 4, 4),
        new MeshPhongMaterial({ color: 0x00ff00, visible: false })
      ) as PickingMesh
      const id = renderDot.connectDotId
      pickingMesh.connectId = id
      pickingMesh.position.copy(renderDot.position)

      this.pickingMeshes.push(pickingMesh)
      this.scene.add(pickingMesh)
    })

    // regions
    this.regionScene = new Scene()
    this.renderRegions = []
    regions.forEach(region => {
      this.connectRegions[region.optics_cluster] = region
      const geometryVertices = region.vertices.map(vertex =>
        new Vector3(vertex.x, vertex.y, vertex.z).multiplyScalar(
          options.sceneSettings.sizeAdjustment
        )
      )

      const geometry = new ConvexBufferGeometry(geometryVertices)
      const materialOptions = {
        flatShading: true,
        depthWrite: false,
        transparent: true,
        opacity: 0.2,
      }
      const material = new MeshNormalMaterial(materialOptions)

      geometry.computeBoundingBox()
      geometry.computeBoundingSphere()

      const mesh = new Mesh(geometry, material) as PickingMesh
      mesh.connectId = region.optics_cluster

      mesh.visible = false

      this.regionScene.add(mesh)
      this.renderRegions.push({ mesh, regionId: region.optics_cluster })
    })

    // lines
    this.renderLineObject = new LineSegments(
      new Geometry(),
      new LineBasicMaterial({ vertexColors: VertexColors })
    )
    this.scene.add(this.renderLineObject)

    this.renderer = this.buildRenderer()
    this.canvas = this.canvas

    this.previousCameraPosition = new Vector3(0, 0, 0)
    this.camera = this.buildCamera()
    this.controls = this.buildControls()

    this.onMouseDown = (): void => {}
    this.onMouseUp = (): void => {}
    this.onMouseOverDot = (): void => {}
    this.onMouseEnterDot = (): void => {}
    this.onMouseLeaveDot = (): void => {}
    this.onClickDot = (dot: ConnectDot): void => {
      console.log('onClickDot', dot)
    }
    this.onDblClickDot = (dot: ConnectDot): void => {
      console.log('onDblClickDot', dot)
    }
    this.onMouseEnterRegion = (): void => {}
    this.onMouseLeaveRegion = (): void => {}
    this.onClickRegion = (region: ConnectRegion): void => {
      console.log('onClickRegion', region)
    }
    this.onDblClickRegion = (region: ConnectRegion): void => {
      console.log('onDblClickRegion', region)
    }

    this.buildLights()

    this.buildDataPoints()

    this.regionScene.add(this.camera)
    this.scene.add(this.camera)

    // this.scene.background = new Color(this.options.rendererSettings.backgroundColor)

    // composer for blurs

    this.composer = new EffectComposer(this.renderer)

    // @ts-ignore-next-line
    const mainScenePass = new SimpleRenderPass(this.scene, this.camera, true)
    this.composer.addPass(mainScenePass)

    // @ts-ignore-next-line
    const regionScenePass = new SimpleRenderPass(this.regionScene, this.camera, false)
    this.regionScene.background = new Color(this.options.rendererSettings.backgroundColor)
    this.composer.addPass(regionScenePass)

    this.onWindowResize()

    this.fpsWatch = new FpsWatch()

    this.setAnimate(true)
  }

  public setRenderQuality = (renderQuality: RenderQuality) => {
    this.renderQuality = renderQuality
    localStorage.setItem('renderQuality', renderQuality)
    console.log('switching renderQuality to', this.renderQuality)
  }

  public setAnimate = (value: boolean): void => {
    this.doAnimate = value
    if (value) this.fpsWatch = new FpsWatch()
    requestAnimationFrame(this.animate)
  }

  private animate = (time: number): void => {
    this.render(time)
    Object.keys(this.tweens).forEach(key => {
      this.tweens[key].update(time)
    })

    this.stats.update()
    this.fpsWatch.update()

    if (this.fpsWatch.fpsSmoothed < 30 && this.renderQuality !== 'fast') {
      switch (this.renderQuality) {
        case 'super':
          this.setRenderQuality('good')
          break
        default:
          this.setRenderQuality('fast')
      }
    }
    if (this.fpsWatch.fpsSmoothed > 55 && this.renderQuality !== 'super') {
      switch (this.renderQuality) {
        case 'fast':
          this.setRenderQuality('good')
          break
        default:
          this.setRenderQuality('super')
      }
    }

    if (this.doAnimate) {
      requestAnimationFrame(this.animate)
    }
  }

  public setHighlights(highlights: { id: number; color: string }[]) {
    // clear existing highlights
    this.renderDots.forEach(renderDot => {
      renderDot.highlight = false
    })
    highlights.forEach(highlight => {
      const dot = this.dotsById[highlight.id]
      if (dot) {
        dot.highlight = true
        dot.highlightColor = new Color(highlight.color)
      }
    })
  }

  public setAvailableDots(ids: number[]) {
    // console.log('setAvailable', ids)
    this.renderDots.forEach(renderDot => {
      renderDot.available = ids.includes(renderDot.connectDotId)
    })
  }

  public setActive(ids: number[]) {
    // console.log('setActive', ids.length)
    this.renderDots.forEach(renderDot => {
      renderDot.active = ids.includes(renderDot.connectDotId)
      if (!renderDot.active) {
        renderDot.highlight = false
      }
    })
    this.pickingMeshes.forEach(pickingMesh => {
      pickingMesh.visible = ids.includes(pickingMesh.connectId)
    })
  }

  public setActiveRegions(ids: number[]) {
    // console.log('setActiveRegions', ids.length)

    this.renderRegions.forEach(renderRegion => {
      renderRegion.mesh.visible = ids.includes(renderRegion.regionId)
    })
  }

  public setActiveLines(pairs: LinePair[]) {
    // console.log('setActiveLines', pairs.length)
    const geometry = new Geometry()
    pairs.forEach(pair => {
      if (
        this.dotsById[pair.startId] &&
        this.dotsById[pair.endId] &&
        (this.dotsById[pair.startId].active && this.dotsById[pair.endId].active)
      ) {
        geometry.vertices.push(this.dotsById[pair.startId].position)
        geometry.vertices.push(this.dotsById[pair.endId].position)
        const color = new Color(pair.color)
        geometry.colors.push(color)
        geometry.colors.push(color)
      }
    })
    this.renderLineObject.geometry = geometry
    this.renderLineObject.geometry.verticesNeedUpdate = true
    this.renderLineObject.geometry.colorsNeedUpdate = true
  }

  // eslint-disable-next-line class-methods-use-this
  private getPointInBetweenByLen(pointA: Vector3, pointB: Vector3, length: number): Vector3 {
    const dir = pointB
      .clone()
      .sub(pointA)
      .normalize()
      .multiplyScalar(length)
    return pointA.clone().add(dir)
  }

  public focusDots(dotIds: number[], onCompleteCallback?: () => void) {
    let focusDots: RenderDot[] = []
    if (dotIds.length === 0) {
      focusDots = this.renderDots
    } else {
      focusDots = this.renderDots.filter(dot => dotIds.includes(dot.connectDotId))
    }
    const activePoints = focusDots.map(dot => dot.position)
    const boundingBox = new Box3()
    boundingBox.setFromPoints(activePoints)
    const center = new Vector3()
    boundingBox.getCenter(center)
    const radius =
      +this.options.sceneSettings.focusScaleFactor * boundingBox.min.distanceTo(boundingBox.max) +
      this.options.sceneSettings.focusOffset
    const { fov } = this.camera
    const portraitFactor = Math.max(1, 1 / this.camera.aspect)

    const targetDistance = Math.max(
      this.controls.minDistance,
      (portraitFactor * radius) / Math.tan(fov)
    )

    const newCameraPosition = this.getPointInBetweenByLen(
      center,
      this.camera.position,
      targetDistance
    )

    this.controls.enabled = false

    this.tweens.controlsTarget = new TWEEN.Tween(this.controls.target)
      .to(center, this.options.sceneSettings.tween.duration)
      .easing(this.options.sceneSettings.tween.easing)
      .onComplete(() => {
        delete this.tweens.controlsTarget
        // this is to set reset controls state
        // prepare reset values for control
        this.controls.target0 = this.controls.target.clone()
        this.controls.position0 = this.controls.object.position.clone()
        this.controls.up0 = this.controls.object.up.clone()
        this.controls.reset()
        this.controls.enabled = true
      })
      .start()

    this.tweens.cameraPosition = new TWEEN.Tween(this.camera.position)
      .to(newCameraPosition, this.options.sceneSettings.tween.duration)
      .easing(this.options.sceneSettings.tween.easing)
      .onComplete(() => {
        delete this.tweens.cameraPosition
        if (onCompleteCallback) onCompleteCallback()
      })
      .start()
  }

  public focusActiveDots() {
    let focusDotIds = this.renderDots.filter(dot => dot.active).map(dot => dot.connectDotId)
    if (focusDotIds.length === 0) {
      focusDotIds = this.renderDots.filter(dot => !dot.active).map(dot => dot.connectDotId)
    }
    this.focusDots(focusDotIds)
  }

  public setColors(colors: { [id: number]: string }) {
    // console.log('setColors')
    this.renderDots.forEach(renderDot => {
      renderDot.color = new Color(colors[renderDot.connectDotId])
    })
  }

  private pick(): { dot?: ConnectDot; position?: Vector3 } {
    const raycaster = new Raycaster()
    this.camera.updateMatrixWorld()
    raycaster.setFromCamera(this.mouse, this.camera)
    const intersects = raycaster.intersectObjects(this.pickingMeshes)
    if (intersects.length > 0) {
      const { connectId: connectDotId, position } = intersects[0].object as PickingMesh
      const dot = this.connectDots[connectDotId]
      return { dot, position }
    }
    return {}
  }

  private pickRegion(): { region?: ConnectRegion; position?: Vector3 } {
    const raycaster = new Raycaster()
    this.camera.updateMatrixWorld()
    raycaster.setFromCamera(this.mouse, this.camera)
    const intersects = raycaster.intersectObjects(
      this.renderRegions.map(renderRegion => renderRegion.mesh)
    )
    if (intersects.length > 0) {
      const { connectId: regionId, position } = intersects[0].object as PickingMesh
      const region = this.connectRegions[regionId]
      return { region, position }
    }
    return {}
  }

  private buildRenderer(): WebGLRenderer {
    const { antialias, autoClear } = this.options.rendererSettings
    const renderer = new WebGLRenderer({ canvas: this.canvas, antialias })
    renderer.autoClear = autoClear
    renderer.autoClearColor = false
    renderer.autoClearDepth = false
    renderer.sortObjects = true
    return renderer
  }

  private buildCamera(): PerspectiveCamera {
    const { fov, near, far, position, zoom } = this.options.cameraSettings
    const aspect = this.canvas.clientWidth / this.canvas.clientHeight
    const camera = new PerspectiveCamera(fov, aspect, near, far)
    camera.position.set(position.x, position.y, position.z)
    camera.zoom = zoom
    return camera
  }

  private buildLights(): void {
    const { point, hemi } = this.options.lightSettings
    const pointLight = new PointLight(point.color, point.intensity, point.distance, point.decay)
    pointLight.position.set(point.position.x, point.position.y, point.position.z)

    const hemiLight = new HemisphereLight(hemi.skyColor, hemi.groundColor, hemi.intensity)
    hemiLight.position.set(hemi.position.x, hemi.position.y, hemi.position.z)
    hemiLight.castShadow = hemi.castShadow

    this.camera.add(hemiLight)
    this.camera.add(pointLight)
  }

  private buildHighlight(): Mesh {
    const highlightGeometry = new SphereBufferGeometry(
      this.options.sceneSettings.dotRadius * 1.24,
      24,
      24
    )

    const highlightMaterial = new MeshBasicMaterial({
      // color: 0x333333,
      side: BackSide,
    })

    highlightMaterial.onBeforeCompile = (shader): void => {
      shader.vertexShader = `
        attribute vec3 offset;
        attribute vec4 aInstanceColor;
        ${shader.vertexShader}
      `
      // eslint-disable-next-line no-param-reassign
      shader.vertexShader = `
        ${shader.vertexShader.replace(
          '#include <begin_vertex>',
          'vec3 transformed = vec3( position + offset );        '
        )}
      `

      // eslint-disable-next-line no-param-reassign
      shader.vertexShader = `
        varying vec4 vInstanceColor;
        ${shader.vertexShader.replace(
          '#include <color_vertex>',
          `#include <color_vertex>
          vInstanceColor = aInstanceColor;
          `
        )}
      `

      // eslint-disable-next-line no-param-reassign
      shader.fragmentShader = `
        varying vec4 vInstanceColor;
        ${shader.fragmentShader.replace(
          'vec4 diffuseColor = vec4( diffuse, opacity );',
          'vec4 diffuseColor = vInstanceColor;'
        )}
      `
    }

    const highlightBufferGeometry = new InstancedBufferGeometry()
    highlightBufferGeometry.index = highlightGeometry.index
    Object.keys(highlightGeometry.attributes).forEach(key => {
      highlightBufferGeometry.attributes[key] = highlightGeometry.attributes[key]
    })

    const offsets: number[] = []
    const colors: number[] = []
    this.renderDots.forEach(dot => {
      offsets.push(dot.position.x, dot.position.y, dot.position.z)
      colors.push(0, 0, 0, 0)
    })
    const offsetAttribute = new InstancedBufferAttribute(new Float32Array(offsets), 3)
    highlightBufferGeometry.addAttribute('offset', offsetAttribute)
    const colorsAttribute = new InstancedBufferAttribute(new Float32Array(colors), 4, true)
    highlightBufferGeometry.addAttribute('aInstanceColor', colorsAttribute)

    const highlightMesh = new Mesh(highlightBufferGeometry, highlightMaterial)

    highlightMesh.frustumCulled = false

    return highlightMesh
  }

  private buildControls(): TrackballControls {
    const {
      rotateSpeed,
      zoomSpeed,
      panSpeed,
      noZoom,
      noPan,
      dynamicDampingFactor,
      keys,
      maxDistance,
      minDistance,
    } = this.options.controlSettings
    const controls = new TrackballControls(this.camera, this.canvas)
    controls.staticMoving = true
    controls.rotateSpeed = rotateSpeed
    controls.zoomSpeed = zoomSpeed
    controls.panSpeed = panSpeed
    controls.noZoom = noZoom
    controls.noPan = noPan
    controls.dynamicDampingFactor = dynamicDampingFactor
    controls.keys = keys
    controls.maxDistance = maxDistance
    controls.minDistance = minDistance
    return controls
  }

  private buildDataPoints(): void {
    // set up materials (same material on all lods)
    // const instanceMaterial = new MeshToonMaterial({ transparent: true })
    const instanceMaterial = new MeshPhongMaterial({ transparent: true })
    instanceMaterial.onBeforeCompile = (shader): void => {
      shader.vertexShader = `
        attribute vec3 offset;
        attribute vec4 aInstanceColor;
        ${shader.vertexShader}
      `
      // eslint-disable-next-line no-param-reassign
      shader.vertexShader = `
        ${shader.vertexShader.replace(
          '#include <begin_vertex>',
          'vec3 transformed = vec3( position + offset );        '
        )}
      `

      // eslint-disable-next-line no-param-reassign
      shader.vertexShader = `
        varying vec4 vInstanceColor;
        ${shader.vertexShader.replace(
          '#include <color_vertex>',
          `#include <color_vertex>
          vInstanceColor = aInstanceColor;
          `
        )}
      `

      // eslint-disable-next-line no-param-reassign
      shader.fragmentShader = `
        varying vec4 vInstanceColor;
        ${shader.fragmentShader.replace(
          'vec4 diffuseColor = vec4( diffuse, opacity );',
          'vec4 diffuseColor = vInstanceColor;'
        )}
      `
    }

    this.lods.forEach(lod => {
      const instancedGeometry = new InstancedBufferGeometry()
      instancedGeometry.index = lod.geometry.index
      Object.keys(lod.geometry.attributes).forEach(key => {
        instancedGeometry.attributes[key] = lod.geometry.attributes[key]
      })

      const offsets: number[] = []
      const colors: number[] = []
      this.renderDots.forEach(dot => {
        offsets.push(dot.position.x, dot.position.y, dot.position.z)
        colors.push(0, 0, 0, 0)
      })
      const offsetAttribute = new InstancedBufferAttribute(new Float32Array(offsets), 3)
      instancedGeometry.addAttribute('offset', offsetAttribute)
      const colorsAttribute = new InstancedBufferAttribute(new Float32Array(colors), 4, true)
      instancedGeometry.addAttribute('aInstanceColor', colorsAttribute)

      const mesh = new Mesh(instancedGeometry, instanceMaterial)

      mesh.frustumCulled = false
      lod.id = mesh.id
      lod.sceneObject = mesh
      this.scene.add(mesh)
    })
  }

  private render(time: number): void {
    this.controls.update()
    this.camera.updateMatrixWorld()

    if (this.needsResize()) {
      this.onWindowResize()
    }

    // sort dots by distance to the camera
    if (this.camera.position.distanceTo(this.previousCameraPosition) > 0.5) {
      // empty lod.dots
      this.lods.forEach(lod => {
        lod.dots = []
      })

      for (let i = 0; i < this.renderDots.length; i += 1) {
        const dot = this.renderDots[i]

        dot.distanceToCamera = this.camera.position.distanceTo(dot.position)

        for (let j = 0; j < this.lods.length; j += 1) {
          const lod = this.lods[j]

          const inRange =
            dot.distanceToCamera >= lod.range[this.renderQuality].min &&
            dot.distanceToCamera < lod.range[this.renderQuality].max

          if (inRange) {
            lod.dots.push(dot)
          }
        }
      }
      this.renderDots.sort((a, b): number => {
        if (a.active && !b.active) {
          return -1
        }
        if (!a.active && b.active) {
          return 1
        }
        return b.distanceToCamera - a.distanceToCamera
      })

      this.previousCameraPosition = this.camera.position.clone()
    }

    // highlights
    const highlightBufferGeometry = this.highlightMesh.geometry as InstancedBufferGeometry
    highlightBufferGeometry.maxInstancedCount = 0
    this.renderDots.forEach(dot => {
      if (dot.highlight) {
        highlightBufferGeometry.attributes.offset.setXYZ(
          highlightBufferGeometry.maxInstancedCount,
          dot.position.x,
          dot.position.y,
          dot.position.z
        )
        highlightBufferGeometry.attributes.aInstanceColor.setXYZ(
          highlightBufferGeometry.maxInstancedCount,
          dot.highlightColor.r,
          dot.highlightColor.g,
          dot.highlightColor.b
        )
        highlightBufferGeometry.maxInstancedCount += 1
      }
    })

    const highlightOffsets = highlightBufferGeometry.attributes.offset as BufferAttribute
    highlightOffsets.needsUpdate = true

    const highlightColors = highlightBufferGeometry.attributes.aInstanceColor as BufferAttribute
    highlightColors.needsUpdate = true

    // update lod objects in scene
    this.lods.forEach(lod => {
      if (lod.id) {
        if (lod.sceneObject) {
          const geometry = lod.sceneObject.geometry as InstancedBufferGeometry
          lod.dots.forEach((dot, index) => {
            const { color } = dot
            // const color = new Color().setHSL((lod.id % 4)/4, 1, 0.5)
            // const color = new Color().setHSL(dot.distanceToCamera * 0.01, 1, 0.5)

            const alpha = dot.active ? 1 : 0.1
            geometry.attributes.offset.setXYZ(index, dot.position.x, dot.position.y, dot.position.z)

            if (dot.available) {
              const animatedColor = color
                .clone()
                .lerp(new Color(1, 1, 1), this.availableColorValue.x)
              geometry.attributes.aInstanceColor.setXYZW(
                index,
                animatedColor.r,
                animatedColor.g,
                animatedColor.b,
                alpha
              )
            } else {
              geometry.attributes.aInstanceColor.setXYZW(index, color.r, color.g, color.b, alpha)
            }
          })
          const offsetBufferAttribute = geometry.attributes.offset as BufferAttribute
          offsetBufferAttribute.needsUpdate = true
          const colorBufferAttribute = geometry.attributes.aInstanceColor as BufferAttribute
          colorBufferAttribute.needsUpdate = true
          geometry.maxInstancedCount = lod.dots.length
          lod.sceneObject.matrixWorldNeedsUpdate = true
        }
      }
    })

    // this.renderer.clear(true, true, true)
    // this.renderer.render(this.scene, this.camera)
    // console.log(this.renderer.info.render)

    this.composer.render(time)
    // console.log(this.composer.renderer.info.render)
  }

  private setMouse(x: number, y: number): void {
    this.mouse.set((x / this.canvas.offsetWidth) * 2 - 1, -(y / this.canvas.offsetHeight) * 2 + 1)
  }

  public onMouseMove(x: number, y: number): void {
    this.setMouse(x, y)
    const { dot } = this.pick()
    if (dot) {
      if (this.intersectedDotId === dot.id) {
        // still over
        this.onMouseOverDot(dot)
      } else {
        // new intersection
        this.onMouseEnterDot(dot)
        this.intersectedDotId = dot.id
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this.intersectedDotId) {
        this.onMouseLeaveDot(this.connectDots[this.intersectedDotId])
        this.intersectedDotId = undefined
      }
    }

    const { region } = this.pickRegion()
    if (region) {
      if (this.intersectedRegionId !== region.optics_cluster) {
        // new intersection
        this.onMouseEnterRegion(region)
        this.intersectedRegionId = region.optics_cluster
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this.intersectedRegionId) {
        this.onMouseLeaveRegion(this.connectRegions[this.intersectedRegionId])
        this.intersectedRegionId = undefined
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public onDblClick(x: number, y: number): void {
    this.setMouse(x, y)
    const { dot } = this.pick()
    if (dot) {
      this.onDblClickDot(dot)
    } else {
      this.intersectedDotId = undefined
    }
  }

  public onClick(x: number, y: number): void {
    this.setMouse(x, y)
    const { dot } = this.pick()
    if (dot) {
      this.onClickDot(dot)
    } else {
      this.intersectedDotId = undefined
    }
  }

  private needsResize(): boolean {
    const size = new Vector2()
    return this.renderer.getSize(size).x !== this.canvas.offsetWidth
  }

  public onWindowResize(): void {
    this.camera.aspect = this.canvas.offsetWidth / this.canvas.offsetHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight, false)
    this.controls.handleResize()
  }
}
