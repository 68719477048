import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LastPageIcon from '@material-ui/icons/LastPage'
import StrainLegendIcon from './StrainLegendIcon'
import Filters, { FilterProps } from './Filters'
import { StateDot } from '../UI'
import { Theme } from '../theme/theme'
import SearchBar, { SearchBarProps } from './SearchBar'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  drawerHeader: {
    height: 64,
    flexShrink: 0,
    zIndex: 10,
    backgroundColor: '#f5f5f5',
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  listItemSecondaryText: {
    fontSize: 12,
  },
  searchResults: {
    overflowY: 'scroll',
  },
  resultIcon: {
    margin: theme.spacing(0, 2),
  },
  table: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    borderBottom: 'none',
  },
  tableBody: {
    flexShrink: 0,
    overflowY: 'auto',
    width: '100%',
  },
  tableRow: {
    width: '100%',
    padding: 0,
    '&:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },
  },
  tableCell: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  tableFooter: {
    width: '100%',
    zIndex: 10,
    alignSelf: 'flex-end',
    flex: 0,
    borderTop: '0.5px solid rgba(0,0,0,0.12)',
  },
  tableWrapper: {
    flex: 1,
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  tableActions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}))

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void
}

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
}: TablePaginationActionsProps) => {
  const classes = useStyles()

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.tableActions}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={'First Page'}>
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={'Previous Page'}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={'Next Page'}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={'Last Page'}>
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export interface SearchDrawerProps
  extends Pick<FilterProps, 'filters' | 'setFilters'>,
    Pick<SearchBarProps, 'searchQuery' | 'setSearchQuery'> {
  onCloseDrawer: () => void
  dots?: StateDot[]
  searchResults?: StateDot[]
  onMouseEnterSearchResult: (dotId: number) => void
  onClickSearchResult: (dotId: number) => void
}

const SearchDrawer: React.FC<SearchDrawerProps> = ({
  dots,
  searchQuery,
  onCloseDrawer,
  setSearchQuery,
  searchResults,
  filters,
  setFilters,
  onMouseEnterSearchResult,
  onClickSearchResult,
}) => {
  const classes = useStyles()
  const rows = searchResults !== undefined ? searchResults : []
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const maxWidth600 = useMediaQuery('(max-width:600px)')

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <div className={classes.root}>
      <div className={classes.drawerHeader}>
        <SearchBar
          dots={dots}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onCloseDrawer={onCloseDrawer}
        />
      </div>
      <Filters filters={filters} setFilters={setFilters} />
      <div className={classes.tableWrapper}>
        <div style={{ overflow: 'auto', flex: 1 }}>
          <Table className={classes.table}>
            <TableBody className={classes.tableBody}>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(dot => (
                <TableRow className={classes.tableRow} key={dot.id}>
                  <TableCell className={classes.tableCell} component={'td'} scope={'row'}>
                    <ListItem
                      onMouseEnter={() => {
                        onMouseEnterSearchResult(dot.id)
                      }}
                      onClick={() => {
                        onClickSearchResult(dot.id)
                        if (maxWidth600) {
                          onCloseDrawer()
                        }
                      }}
                      className={classes.listItem}
                      button>
                      <Container
                        style={{
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}>
                        <StrainLegendIcon
                          className={classes.resultIcon}
                          size={20}
                          color={dot.color || '#ccc'}
                        />
                        <Container style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                          <Typography component={'h2'}>{dot.strain}</Typography>
                          <Typography
                            className={classes.listItemSecondaryText}
                            color={'textSecondary'}>
                            {`${dot.taxonomy}  -  ${dot.locale}`}
                          </Typography>
                        </Container>
                      </Container>
                    </ListItem>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} style={{ borderBottom: 'none' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <Table className={classes.tableFooter}>
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={0}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  )
}

export default SearchDrawer
