import React, { useState, ReactNode, TouchEvent, useEffect } from 'react'

interface SwipeableProps {
  children: ReactNode
  onSwipe: () => void
  showCards: boolean
  className: string
  enabled: boolean
}

// enables swipe down on card carousel to close cards

const Swipeable: React.FC<SwipeableProps> = ({
  enabled,
  className,
  showCards,
  children,
  onSwipe,
}) => {
  const [touched, setTouched] = useState<boolean>(false)
  const [bottom, setBottom] = useState<number>(0)
  const [touchStartY, setTouchStartY] = useState<number>(0)
  const [touchStartX, setTouchStartX] = useState<number>(0)
  const [elHeight, setElHeight] = useState<number>(0)

  useEffect(() => {
    if (!showCards) {
      setBottom(-1200)
    } else {
      setBottom(0)
    }
  }, [showCards])

  const handleRemoveSelf = () => {
    setTimeout(() => {
      onSwipe()
    }, 250)
  }

  const handleStart = (clientY: number, clientX: number) => {
    setTouchStartY(clientY)
    setTouchStartX(clientX)
    setTouched(true)
  }

  const handleEnd = () => {
    setTouchStartY(0)
    setTouched(false)

    // if 60% or more of element is offscreen, remove
    if (bottom <= -(elHeight * 0.6)) {
      setBottom(-(elHeight + 100))
      handleRemoveSelf()
    } else {
      setBottom(0)
    }
  }

  const handleMove = (clientY: number, clientX: number) => {
    if (touched) {
      const touchY = clientY
      const touchX = clientX
      const deltaY = touchStartY - touchY
      const deltaX = touchStartX - touchX
      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        setBottom(deltaY)
      }
    }
  }

  const handleTouchStart = (touchStartEvent: TouchEvent) => {
    touchStartEvent.preventDefault()
    const { height } = touchStartEvent.currentTarget.getBoundingClientRect()
    setElHeight(height)
    handleStart(touchStartEvent.targetTouches[0].clientY, touchStartEvent.targetTouches[0].clientX)
  }

  const handleTouchMove = (touchMoveEvent: TouchEvent) => {
    handleMove(touchMoveEvent.targetTouches[0].clientY, touchMoveEvent.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    handleEnd()
  }

  return (
    <div
      className={className}
      onTouchStart={enabled ? touchStartEvent => handleTouchStart(touchStartEvent) : undefined}
      onTouchMove={enabled ? touchMoveEvent => handleTouchMove(touchMoveEvent) : undefined}
      onTouchEnd={enabled ? () => handleTouchEnd() : undefined}
      style={
        enabled
          ? { bottom: `${bottom}px`, transition: 'bottom 250ms ease-in-out', touchAction: 'none' }
          : undefined
      }>
      {children}
    </div>
  )
}

export default Swipeable
