import { Scene, Camera, WebGLRenderer, WebGLRenderTarget } from 'three'

import { Pass } from 'three/examples/jsm/postprocessing/Pass'

export class SimpleRenderPass extends Pass {
  public constructor(scene: Scene, camera: Camera, shouldClearColor: boolean) {
    super()

    this.scene = scene

    this.camera = camera

    this.shouldClearColor = shouldClearColor
  }

  public scene: Scene

  public camera: Camera

  public shouldClearColor: boolean

  /* eslint-disable @typescript-eslint/no-unused-vars */
  public render(
    renderer: WebGLRenderer,
    writeBuffer: WebGLRenderTarget,
    readBuffer: WebGLRenderTarget,
    deltaTime: number,
    maskActive: boolean
  ): void {
    if (this.shouldClearColor === true) {
      renderer.clear(true, true, true)
    } else {
      renderer.clear(false, true, false)
    }

    renderer.setRenderTarget(null)

    renderer.render(this.scene, this.camera)
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

export default SimpleRenderPass
