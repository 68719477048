import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

export interface RegionLegendIconProps {
  badgeUrl: string
  size?: number
  spacing?: number
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '50%',
  },
}))

const RegionLegendIcon: React.FC<RegionLegendIconProps> = ({ badgeUrl, size = 32, className }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.root, className)} style={{ height: size, width: size }}>
      <img alt={'region'} src={badgeUrl} style={{ opacity: 1, height: size, width: size }} />
    </div>
  )
}

export default RegionLegendIcon
