import React, { ReactNode } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme/theme'

interface Props {
  children: ReactNode
}

const ConnectThemeProvider: React.FC<Props> = ({ children }: Props) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ConnectThemeProvider
