import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import SearchIcon from '@material-ui/icons/Search'
import Link from '@material-ui/core/Link'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import LandscapeIcon from '@material-ui/icons/LandscapeOutlined'
import BookmarksIcon from '@material-ui/icons/BookmarksOutlined'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import CardMedia from '@material-ui/core/CardMedia'
import classnames from 'classnames'
import { Theme } from '../theme/theme'
import { DrawerType } from '../UI'
import ConnectLogo from '../../../assets/connect-logo-by-cc.png'

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    display: 'flex',
    flexShrink: 0,
    zIndex: 1,
  },
  drawerPaper: {
    width: 320,
  },
  drawerHeader: {
    height: 128,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'flex',
    zIndex: 10,
    padding: theme.spacing(1, 1, 3, 2),
  },
  closeButton: {
    padding: theme.spacing(0),
  },
  menuList: {
    padding: theme.spacing(1),
  },
  menuListItem: {
    height: 48,
    padding: theme.spacing(0, 2),
    '&:hover': {
      cursor: 'pointer',
      color: theme.branding.connect.main,
      '& div': {
        color: theme.branding.connect.main,
      },
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  iconButton: {
    '&:hover': {
      background: 'transparent',
    },
  },
  mediaWrapper: {
    marginTop: theme.spacing(3),
    height: 70,
    width: 260,
  },
  media: {
    height: 0,
    paddingTop: '23.05%',
  },
  connectLogo: {
    width: '100%',
  },
  flex: {
    flex: 1,
  },
  footer: {
    width: '100%',
    padding: theme.spacing(2, 3),
  },
}))

export interface MenuListButtonProps {
  label: string
  icon: ReactElement
  onClick?: () => void
  className?: string
}

const MenuListButton: React.FC<MenuListButtonProps> = ({ label, icon, onClick }) => {
  const classes = useStyles()
  return (
    <ListItem className={classes.menuListItem} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  )
}

export interface MenuDrawerProps {
  open: boolean
  setShowDrawer: (bool: boolean) => void
  setShowMenuDrawer: (bool: boolean) => void
  setShowInfo: (bool: boolean) => void
  setCurrentDrawer: (drawer: DrawerType) => void
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({
  open,
  setShowDrawer,
  setShowMenuDrawer,
  setCurrentDrawer,
  setShowInfo,
}) => {
  const classes = useStyles()

  const handleOpenDrawer = (drawer: DrawerType) => {
    setCurrentDrawer(drawer)
    setShowMenuDrawer(false)
    setShowDrawer(true)
  }

  return (
    <Drawer
      className={classes.drawer}
      open={open}
      onClose={() => setShowMenuDrawer(false)}
      classes={{
        paper: classes.drawerPaper,
      }}>
      <div className={classes.drawerHeader}>
        <div className={classes.mediaWrapper}>
          <CardMedia
            className={classnames(classes.media, classes.connectLogo)}
            image={ConnectLogo}
            title={'Connect Logo'}
          />
        </div>
        <IconButton
          disableFocusRipple
          disableTouchRipple
          className={classnames(classes.iconButton, classes.closeButton)}
          onClick={() => setShowMenuDrawer(false)}
          aria-label={'Close'}>
          <KeyboardArrowLeft fontSize={'inherit'} />
        </IconButton>
      </div>
      <Divider />
      <List className={classes.menuList} dense>
        <MenuListButton
          icon={<SearchIcon />}
          label={'Search Strains'}
          onClick={() => handleOpenDrawer('search')}
        />
        <MenuListButton
          icon={<BookmarksIcon />}
          label={'Bookmarks'}
          onClick={() => handleOpenDrawer('bookmarks')}
        />
        <MenuListButton
          icon={<LandscapeIcon />}
          label={'Show Regions'}
          onClick={() => handleOpenDrawer('regions')}
        />
      </List>
      <Divider />
      <List className={classes.menuList} dense>
        <MenuListButton icon={<InfoIcon />} label={'Info'} onClick={() => setShowInfo(true)} />
      </List>
      <div className={classes.flex} />
      <div className={classes.footer}>
        <Link
          variant={'caption'}
          target={'_blank'}
          href={'https://confidentcannabis.com/terms-of-use/'}>
          Terms of Use
        </Link>
        &nbsp;&nbsp;
        <Link
          variant={'caption'}
          target={'_blank'}
          href={'https://confidentcannabis.com/privacy-policy/'}>
          Privacy
        </Link>
      </div>
    </Drawer>
  )
}

export default MenuDrawer
