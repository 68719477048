import React, { MouseEvent, ReactNode, forwardRef } from 'react'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface Props {
  color?: 'primary' | 'secondary' | 'inherit' | 'default' | undefined
  onClick: (e: MouseEvent) => void
  ariaLabel: string
  className?: string
  icon: ReactNode
  label?: string
  variant?: 'extended' | 'round'
}

const CCActionButton: React.FC<Props> = forwardRef(
  ({ color, onClick, ariaLabel, className, icon, label, variant }: Props, ref) => {
    const maxWidth600 = useMediaQuery('(max-width:600px)')
    return (
      <Fab
        disableRipple
        disableTouchRipple
        innerRef={ref}
        size={maxWidth600 ? 'small' : 'medium'}
        color={color}
        onClick={onClick}
        aria-label={ariaLabel}
        className={className}
        variant={variant}>
        {icon}
        {label && <Typography style={{ marginLeft: '8px' }}>{label}</Typography>}
      </Fab>
    )
  }
)

export default CCActionButton
