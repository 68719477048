import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import TWEEN from '@tweenjs/tween.js'
import ConnectEngine, { ConnectEngineOptions } from './ConnectEngine'
import ConnectDot, { LinePair } from '../models/ConnectDot'
import ConnectRegion from '../models/ConnectRegion'

export interface VisualizationProps {
  dots?: ConnectDot[]
  regions?: ConnectRegion[]
  animate: boolean
  options?: ConnectEngineOptions
  onLoadProgress?: (progress: number) => void
  onMouseEnterDot?: (dot: ConnectDot) => void
  onMouseLeaveDot?: (dot: ConnectDot) => void
  onDblClickDot?: (dot: ConnectDot) => void
  onClickDot?: (dot: ConnectDot) => void
  onMouseEnterRegion?: (region: ConnectRegion) => void
  onMouseLeaveRegion?: (region: ConnectRegion) => void
  onDblClickRegion?: (region: ConnectRegion) => void
  onClickRegion?: (region: ConnectRegion) => void
  activeDots?: number[]
  focusDots?: number[]
  availableDots?: number[]
  activeLines?: LinePair[]
  highlightDots?: { id: number; color: string }[]
  activeRegions?: number[]
  dotColors?: Record<number, string>
  className?: string
}

let currentTouch: Touch | undefined
let mouseMoves = 0
let mouseDown = false
let lastClickDot = {
  id: -1,
  time: Date.now(),
}
const isDragging = () => mouseDown && mouseMoves > 3

const Visualization: React.FC<VisualizationProps> = ({
  dots,
  regions,
  activeDots,
  focusDots,
  activeRegions,
  activeLines,
  availableDots,
  highlightDots,
  dotColors,
  animate,
  onLoadProgress,
  onMouseEnterDot,
  onMouseLeaveDot,
  onDblClickDot,
  onClickDot,
  onMouseEnterRegion,
  onMouseLeaveRegion,
  onDblClickRegion,
  onClickRegion,
  options,
  className,
}: VisualizationProps) => {
  const renderTarget = useRef<HTMLCanvasElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  let connectEngine: ConnectEngine

  const full: CSSProperties = {
    zIndex: 1,
    height: '100%',
    width: '100%',
    top: '0px',
    left: '0px',
    background: '#ebecee',
  }

  const tooltip: CSSProperties = {
    position: 'absolute',
    padding: '4px 8px',
    color: '#fafafa',
    background: '#000',
    opacity: 0.6,
    borderRadius: 3,
    zIndex: 49,
    transform: 'translate(-50%, -150%)',
    display: 'none',
    pointerEvents: 'none',
    fontSize: '0.75rem',
    fontFamily: '"Open Sans",Roboto,"Helvetica Neue",Arial,sans-serif',
    fontWeight: 400,
    lineHeight: 1.66,
  }

  const [visualization, setVisualization] = useState<ConnectEngine>()

  useEffect(() => {
    if (dots && regions && options && renderTarget.current !== null) {
      connectEngine = new ConnectEngine(renderTarget.current, dots, regions, options)
      setVisualization(connectEngine)
      if (onLoadProgress) onLoadProgress(75)
    }
  }, [dots, regions, options])

  useEffect(() => {
    if (visualization) {
      window.addEventListener('resize', () => {
        visualization.onWindowResize()
      })

      visualization.canvas.addEventListener(
        'touchstart',
        event => {
          const touch = event.touches[0]
          currentTouch = touch
        },
        false
      )
      visualization.canvas.addEventListener(
        'touchend',
        event => {
          const touch = event.changedTouches[0]
          if (currentTouch && touch) {
            visualization.onClick(
              touch.clientX - visualization.canvas.offsetLeft,
              touch.clientY - visualization.canvas.offsetTop
            )
          }
        },
        false
      )
      visualization.canvas.addEventListener(
        'touchmove',
        event => {
          if (currentTouch) {
            if (
              Math.abs(event.changedTouches[0].clientX - currentTouch.clientX) > 3 ||
              Math.abs(event.changedTouches[0].clientY - currentTouch.clientY) > 3
            ) {
              currentTouch = undefined
            }
          }
        },
        false
      )

      visualization.canvas.addEventListener('mousedown', () => {
        mouseMoves = 0
        mouseDown = true
        if (tooltipRef.current) {
          tooltipRef.current.style.display = 'none'
        }
      })

      visualization.canvas.addEventListener('mouseup', event => {
        let shouldClick = false
        if (!isDragging()) {
          shouldClick = true
        }
        mouseDown = false
        document.body.style.cursor = 'auto'
        if (shouldClick) {
          visualization.onClick(event.offsetX, event.offsetY)
        }
      })

      visualization.canvas.addEventListener('mouseleave', () => {
        mouseDown = false
        document.body.style.cursor = 'auto'
        if (tooltipRef.current) {
          tooltipRef.current.style.display = 'none'
        }
      })

      visualization.canvas.addEventListener('mousemove', event => {
        if (mouseDown) {
          mouseMoves += 1
        }
        if (isDragging()) {
          document.body.style.cursor = 'grabbing'
        }
        if (tooltipRef.current) {
          tooltipRef.current.style.left = `${event.clientX}px`
          tooltipRef.current.style.top = `${event.clientY}px`
        }
        visualization.onMouseMove(event.offsetX, event.offsetY)
      })
    }
  }, [visualization])

  useEffect(() => {
    if (visualization) {
      visualization.onMouseEnterDot = dot => {
        if (!isDragging()) {
          document.body.style.cursor = 'pointer'
          if (tooltipRef.current) {
            tooltipRef.current.innerText = dot.strain
            tooltipRef.current.style.display = 'unset'
          }
        }
        if (onMouseEnterDot) onMouseEnterDot(dot)
      }
      visualization.onMouseLeaveDot = dot => {
        document.body.style.cursor = 'auto'
        if (tooltipRef.current) {
          tooltipRef.current.style.display = 'none'
        }
        if (onMouseLeaveDot) onMouseLeaveDot(dot)
      }
      if (onDblClickDot) visualization.onDblClickDot = onDblClickDot
      visualization.onClickDot = dot => {
        if (lastClickDot.id === dot.id && Date.now() - lastClickDot.time < 500) {
          // clicked on existing compare (using as dblClick for mobile)
          if (onDblClickDot) onDblClickDot(dot)
        } else {
          // eslint-disable-next-line no-lonely-if
          if (onClickDot) onClickDot(dot)
        }
        // store click for double click detection on mobile
        lastClickDot = { id: dot.id, time: Date.now() }
      }

      if (onMouseEnterRegion) visualization.onMouseEnterRegion = onMouseEnterRegion
      if (onMouseLeaveRegion) visualization.onMouseLeaveRegion = onMouseLeaveRegion
      if (onDblClickRegion) visualization.onDblClickRegion = onDblClickRegion
      if (onClickRegion) visualization.onClickRegion = onClickRegion
    }
  }, [
    visualization,
    onMouseEnterDot,
    onMouseLeaveDot,
    onDblClickDot,
    onClickDot,
    onMouseEnterRegion,
    onMouseLeaveRegion,
    onDblClickRegion,
    onClickRegion,
  ])

  useEffect(() => {
    if (visualization && activeDots) {
      visualization.setActive(activeDots)
    }
  }, [activeDots])

  useEffect(() => {
    if (visualization && focusDots) {
      visualization.focusDots(focusDots)
    }
  }, [focusDots])

  useEffect(() => {
    if (visualization && highlightDots) visualization.setHighlights(highlightDots)
  }, [highlightDots])

  useEffect(() => {
    if (visualization && activeRegions) {
      visualization.setActiveRegions(activeRegions)
    }
  }, [activeRegions])

  useEffect(() => {
    if (visualization && activeLines) {
      visualization.setActiveLines(activeLines)
    }
  }, [activeLines])

  useEffect(() => {
    if (visualization && availableDots) {
      visualization.setAvailableDots(availableDots)
    }
  }, [availableDots])

  useEffect(() => {
    if (visualization && dotColors) {
      visualization.setColors(dotColors)
      if (onLoadProgress) {
        onLoadProgress(80)
        setTimeout(() => onLoadProgress(100), 500)
      }
    }
  }, [visualization, dotColors])

  useEffect(() => {
    if (visualization) {
      visualization.setAnimate(animate)
    }
  }, [animate])

  return (
    <div style={full} className={className}>
      <div style={tooltip} ref={tooltipRef}>
        tooltip!
      </div>
      <canvas style={full} ref={renderTarget} />
    </div>
  )
}

Visualization.defaultProps = {
  options: {
    sceneSettings: {
      sizeAdjustment: 8,
      dotRadius: 0.6,
      tween: {
        duration: 1200,
        easing: TWEEN.Easing.Quadratic.InOut,
      },
      focusOffset: 15,
      focusScaleFactor: 1.6,
    },
    controlSettings: {
      rotateSpeed: 2.0,
      zoomSpeed: 1.2,
      panSpeed: 0.8,
      noZoom: false,
      noPan: false,
      dynamicDampingFactor: 0.3,
      keys: [65, 83, 68],
      maxDistance: 1000,
      minDistance: 3,
    },
    lightSettings: {
      point: {
        distance: 0,
        decay: 1,
        color: 0xffffff,
        intensity: 0.15,
        position: {
          x: 45,
          y: 45,
          z: 75,
        },
      },
      hemi: {
        skyColor: 0xffffff,
        groundColor: 0xffffff,
        intensity: 0.88,
        position: {
          x: 0,
          y: 200,
          z: 0,
        },
        castShadow: false,
        shadowCameraVisible: false,
      },
    },
    rendererSettings: {
      backgroundColor: '#ebecee',
      antialias: true,
      autoClear: false,
      shadowMap: {
        enabled: false,
      },
      shadowMapSoft: true,
    },
    cameraSettings: {
      fov: 45, // — Camera frustum vertical field of view.
      aspect: window.innerWidth / window.innerHeight, // — Camera frustum aspect ratio.
      near: 0.001, // — Camera frustum near plane.
      far: 100000,
      zoom: 1,
      focalLength: null,
      position: {
        x: 20,
        y: 50,
        z: 50,
      },
      pitch: 0,
      roll: 0,
      yaw: 0,
    },
  },
}

export default Visualization
