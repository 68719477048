import React, { MouseEvent, ReactNode, useState, useEffect } from 'react'
import * as _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import LayersOutlined from '@material-ui/icons/LayersOutlined'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import Tooltip from '@material-ui/core/Tooltip'
import { useMediaQuery } from '@material-ui/core'
import classnames from 'classnames'
import ColorOptionMenu from './ColorOptionMenu'
import CCActionButton from './CCActionButton'
import Legend, { LegendItem } from './Legend'
import { Theme } from '../theme/theme'
import {
  CategoryInfo,
  CannabinoidInfo,
  IndicaSativaInfo,
  MethodInfo,
} from '../../models/ConnectDot'

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    margin: theme.spacing(1),
    boxShadow: 'none',
  },
  container: {
    position: 'absolute',
    zIndex: 51,
    bottom: 0,
    right: 0,
    margin: theme.spacing(2),
  },
  activeIcon: {
    background: theme.branding.greyPalette.medium,
    color: theme.palette.common.white,
  },
}))

interface Props {
  colorOption: 'c' | 'ish' | 'method'
  setColorOption: (str: 'c' | 'ish' | 'method') => void
  categoryInfo: CategoryInfo
  filtered: boolean
  onClickClearFilters: () => void
}

const ActionButtons: React.FC<Props> = ({
  colorOption,
  setColorOption,
  categoryInfo,
  filtered,
  onClickClearFilters,
}) => {
  const classes = useStyles()

  const maxWidth600 = useMediaQuery('(max-width:600px)')

  const [legendOpen, setLegendOpen] = useState(false)
  const [colorAnchorEl, setColorAnchorEl] = useState<ReactNode>(null)

  const openColorMenu = (event: MouseEvent) => {
    setColorAnchorEl(event.currentTarget)
  }

  const mapInfoToList = (info: CannabinoidInfo | IndicaSativaInfo | MethodInfo) => {
    const list: LegendItem[] = []
    _.forEach(info, (each: { label: string; color: string }) => {
      if (
        each &&
        each.label !== 'Uncategorized' &&
        each.label !== 'Other' &&
        each.label !== 'Unknown' &&
        each.label !== 'Nationwide' &&
        each.label !== 'Hybrid'
      ) {
        list.push(each)
      }
    })
    return list
  }

  const [legendContents, setLegendContents] = useState(mapInfoToList(categoryInfo.cannabinoidInfo))
  useEffect(() => {
    switch (colorOption) {
      case 'c':
        setLegendContents(mapInfoToList(categoryInfo.cannabinoidInfo))
        break
      case 'ish':
        setLegendContents(mapInfoToList(categoryInfo.indicaSativaInfo))
        break
      case 'method':
        setLegendContents(mapInfoToList(categoryInfo.methodInfo))
        break
      default:
        break
    }
  }, [colorOption])

  const colorOptionMap = {
    c: 'Cannabinoid',
    ish: 'Indica/Sativa',
    method: 'Method',
  }

  return (
    <div className={classes.container}>
      {filtered && (
        <CCActionButton
          variant={'extended'}
          label={'Reset'}
          color={'default'}
          ariaLabel={'Layers'}
          onClick={onClickClearFilters}
          className={classes.fab}
          icon={<Close fontSize={'small'} />}
        />
      )}
      <CCActionButton
        variant={maxWidth600 ? 'round' : 'extended'}
        label={maxWidth600 ? '' : colorOptionMap[colorOption]}
        color={'default'}
        ariaLabel={'Layers'}
        onClick={openColorMenu}
        className={classes.fab}
        icon={<LayersOutlined />}
      />

      <Tooltip
        open={!colorAnchorEl && legendOpen}
        placement={'top-end'}
        title={<Legend contents={legendContents} />}>
        <CCActionButton
          color={'default'}
          ariaLabel={'Legend'}
          onClick={() => setLegendOpen(!legendOpen)}
          className={classnames(classes.fab, legendOpen ? classes.activeIcon : undefined)}
          icon={<FormatListBulleted />}
        />
      </Tooltip>

      <ColorOptionMenu
        anchorEl={colorAnchorEl}
        setAnchorEl={setColorAnchorEl}
        colorOption={colorOption}
        setColorOption={setColorOption}
      />
    </div>
  )
}

export default ActionButtons
