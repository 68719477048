import React, { ChangeEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import FormLabel from '@material-ui/core/FormLabel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import classnames from 'classnames'
import { Theme } from '../theme/theme'

const useStyles = makeStyles((theme: Theme) => ({
  formList: {
    height: theme.spacing(4),
  },
  softBlack: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  formLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  panel: {
    backgroundColor: '#f5f5f5',
    border: 'none',
    width: '100%',
  },
  panelHeader: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(0, 3),
    width: '100%',
    justifyContent: 'space-between',
  },
  panelContents: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
  },
  panelForm: {
    margin: 0,
    backgroundColor: theme.branding.greyPalette.light,
    width: '100%',
  },
}))

export interface CollapsableFilterProps {
  expanded: boolean
  handleExpand: (e: ChangeEvent<{}>, expanded: boolean) => void
  filters: { [key: string]: { value: boolean; label: string } }
  label: string
  handleChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const CollapsableFilter: React.FC<CollapsableFilterProps> = ({
  expanded,
  handleExpand,
  filters,
  label,
  handleChange,
}) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.panelForm} margin={'dense'}>
      <FormGroup>
        <ExpansionPanel
          square
          expanded={expanded}
          onChange={handleExpand}
          elevation={0}
          className={classes.panel}>
          <ExpansionPanelSummary
            className={classes.panelHeader}
            expandIcon={<ExpandMoreIcon className={classes.softBlack} />}>
            <FormLabel color={'primary'}>{label}</FormLabel>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panelContents}>
            {filters &&
              Object.keys(filters).map((key: string) => (
                <FormControlLabel
                  key={key}
                  className={classes.formList}
                  classes={{ label: classnames(classes.formLabel, classes.softBlack) }}
                  label={filters[key].label}
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize={'small'} />}
                      checkedIcon={<CheckBoxIcon fontSize={'small'} />}
                      checked={filters[key].value}
                      onChange={handleChange}
                      value={key}
                      color={'secondary'}
                    />
                  }
                />
              ))}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </FormGroup>
    </FormControl>
  )
}

export default CollapsableFilter
