import React, { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import LinearProgress from '@material-ui/core/LinearProgress'
import './App.css'
import axios from 'axios'
import { SnackbarProvider } from 'notistack'
import UI from './UI/UI'
import ConnectThemeProvider from './UI/ConnectThemeProvider'
import ConnectDot, { CategoryInfo, Cannabinoid, IndicaSativa, Method } from './models/ConnectDot'
import ConnectRegion from './models/ConnectRegion'
import VersionLabel from './UI/components/VersionLabel'
import { Theme } from './UI/theme/theme'
import ConnectLogo from '../assets/connect-logo-by-cc.png'

const ishColorOverrides: { [key: string]: string } = {
  Indica: '#1F89AE',
  Sativa: '#E36D69',
  Hybrid: '#CCC',
  Unknown: '#CCC',
}

interface PayloadGalaxyDot {
  available: boolean
  badge_url: string
  card_color: number
  card_subhead: number
  card_subhead_color: number
  color: number
  gallery_url: string
  group: number
  id: number
  index: number
  inventory_key: string
  ish: number
  method: number
  public_label: string
  size: number
  state: number
  strain: string
  x: number
  y: number
  z: number
  zoom_radius: number
  optics_cluster?: number
}

const categoryInfo: CategoryInfo = {
  cannabinoidInfo: {
    0: { label: 'THC' },
    1: { label: 'THC Dominant' },
    2: { label: 'Balanced' },
    3: { label: 'CBD Dominant' },
    4: { label: 'CBD' },
    5: { label: 'THCv' },
    6: { label: 'Uncategorized' },
  },
  methodInfo: {
    0: { label: 'Greenhouse' },
    1: { label: 'Indoor' },
    2: { label: 'Light Deprivation' },
    3: { label: 'Mixed Light' },
    4: { label: 'Nationwide' },
    5: { label: 'Other' },
    6: { label: 'Outdoor' },
    7: { label: 'Unknown' },
  },
  indicaSativaInfo: {
    0: { label: 'Hybrid' },
    1: { label: 'Indica' },
    2: { label: 'Sativa' },
    3: { label: 'Other' },
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    background: theme.palette.common.white,
    zIndex: 9999,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 400,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
  },
  form: {
    margin: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formTitle: {
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  buttonWrapper: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonRoot: {
    textTransform: 'none',
    boxShadow: 'none',
    margin: theme.spacing(4),
  },
  loader: {
    margin: theme.spacing(8),
    height: 4,
    width: '50%',
  },
}))

interface AgeGateProps {
  onSubmit: () => void
}

const AgeGate: React.FC<AgeGateProps> = ({ onSubmit }) => {
  const classes = useStyles()
  return (
    <FormGroup className={classes.form}>
      <Typography classes={{ h6: classes.formTitle }} variant={'h6'}>
        Are you over 21 years of age?
      </Typography>
      <div className={classes.buttonWrapper}>
        <Button
          classes={{ root: classes.buttonRoot }}
          variant={'contained'}
          color={'secondary'}
          onClick={onSubmit}>
          Yes
        </Button>
        <Button
          classes={{ root: classes.buttonRoot }}
          variant={'contained'}
          href={'https://www.google.com'}>
          No
        </Button>
      </div>
    </FormGroup>
  )
}

interface LoaderProps {
  value: number
}

const Loader: React.FC<LoaderProps> = ({ value = 25 }) => {
  const classes = useStyles()
  return (
    <div className={classes.loader}>
      <LinearProgress variant={'determinate'} value={value} color={'primary'} />
    </div>
  )
}

export const CategoryInfoContext = React.createContext(categoryInfo)
const App: React.FC = () => {
  const classes = useStyles()
  const [dots, setDots] = useState<ConnectDot[]>()
  const [regions, setRegions] = useState<ConnectRegion[]>()
  const [locales, setLocales] = useState<string[]>()
  const [showAgeGate, setShowAgeGate] = useState(true)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [downloading, setDownloading] = useState(false)
  const [visualizationLoading, setVisualizationLoading] = useState(false)

  const fetchData = () => {
    setDownloading(true)
    const dataUrl = process.env.NODE_ENV === 'development' ? '/data/data.json' : '/data/data.json'
    axios
      .get(dataUrl, {
        onDownloadProgress: progressEvent => {
          const percentCompleted = Math.round(progressEvent.loaded / 30000)
          setLoadingProgress(0.5 * percentCompleted)
        },
      })
      .then(response => {
        setLoadingProgress(50)
        const payloadColorings = {
          c: response.data.colors as string[],
          ish: response.data.ish.map((name: string) => ishColorOverrides[name]) as string[],
          method: response.data.methods.map((name: string) =>
            name === 'Unknown' ? '#ccc' : response.data.other_colors[name][0]
          ) as string[],
        }
        payloadColorings.c.forEach((color, index) => {
          categoryInfo.cannabinoidInfo[index as Cannabinoid].color = color
        })
        payloadColorings.ish.forEach((color, index) => {
          categoryInfo.indicaSativaInfo[index as IndicaSativa].color = color
        })
        payloadColorings.method.forEach((color, index) => {
          categoryInfo.methodInfo[index as Method].color = color
        })
        const stateLabels = response.data.states
        const importedDots: ConnectDot[] = response.data.galaxy.map(
          (inputDot: PayloadGalaxyDot): ConnectDot => {
            return {
              available: inputDot.available,
              badgeToken: inputDot.badge_url,
              galleryUrl: inputDot.gallery_url,
              id: inputDot.id,
              inventoryKey: inputDot.inventory_key,
              cannabinoid: inputDot.color,
              method: inputDot.method,
              indicaSativa: inputDot.ish,
              publicLabel: inputDot.public_label,
              locale: stateLabels[inputDot.state],
              strain: inputDot.strain,
              group: inputDot.group,
              position: {
                x: inputDot.x,
                y: inputDot.y,
                z: inputDot.z,
              },
              regionId: inputDot.optics_cluster,
            }
          }
        )
        setDots(importedDots)
        setRegions(response.data.clusters)
        setLocales(response.data.states)
        setVisualizationLoading(true)
        setDownloading(false)
      })
  }

  useEffect(() => {
    const ageOk = localStorage.getItem('ageOk')
    if (ageOk === '1') {
      setShowAgeGate(false)
      fetchData()
    }
  }, [])

  const handleAgeGate = () => {
    localStorage.setItem('ageOk', '1')
    fetchData()
    setShowAgeGate(false)
  }

  return (
    <ConnectThemeProvider>
      <VersionLabel />
      <CssBaseline />
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={2000}>
        {(downloading || visualizationLoading || showAgeGate) && (
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <img className={classes.logo} src={ConnectLogo} alt={'Connect Logo'} />
              {showAgeGate && <AgeGate onSubmit={handleAgeGate} />}
              {(downloading || visualizationLoading) && <Loader value={loadingProgress} />}
            </div>
          </div>
        )}
        {!downloading && (
          <UI
            dots={dots}
            regions={regions}
            locales={locales}
            categoryInfo={categoryInfo}
            onVisualizationProgress={progress => {
              setLoadingProgress(progress)
              if (progress >= 100) setVisualizationLoading(false)
            }}
          />
        )}
      </SnackbarProvider>
    </ConnectThemeProvider>
  )
}

export default App
