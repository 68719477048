import React, { KeyboardEvent, useMemo, useState } from 'react'
import Downshift, { StateChangeOptions, DownshiftState } from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import CancelIcon from '@material-ui/icons/Cancel'
import Divider from '@material-ui/core/Divider'
import { List, ListItem, ListItemText } from '@material-ui/core'
import classnames from 'classnames'
import StrainLegendIcon from './StrainLegendIcon'
import { StateDot } from '../UI'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    zIndex: 10,
  },
  tools: {
    display: 'flex',
    alignItems: 'center',
  },
  suggestions: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    padding: 0,
  },
  suggestionItem: {
    borderTop: '1px solid #cecece',
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,
  },
  suggestionIcon: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  iconButton: {
    '&:hover': {
      background: 'transparent',
    },
  },
  closeDrawerButton: {
    padding: theme.spacing(0, 0.5, 0, 0.25),
  },
  clearInputButton: {
    margin: theme.spacing(0, 1),
  },
  input: {
    fontSize: 15,
    flex: 1,
  },
  divider: {
    width: 1,
    height: 28,
    margin: theme.spacing(1, 0),
  },
}))

export interface SearchBarProps {
  dots?: StateDot[]
  searchQuery: string
  setSearchQuery: (query: string) => void
  onCloseDrawer: () => void
}

const SearchBar: React.FC<SearchBarProps> = ({
  dots,
  searchQuery,
  onCloseDrawer,
  setSearchQuery,
}) => {
  const classes = useStyles()
  const resolvedDots = useMemo(() => dots || [], [dots])

  const [suggestIsOpen, setSuggestIsOpen] = useState<boolean>(false)

  const handleClearSearch = () => {
    setSearchQuery('')
    if (suggestIsOpen) {
      setSuggestIsOpen(false)
    }
  }

  const handleCloseSearchDrawer = () => {
    onCloseDrawer()
  }

  const handleOnStateChange = (changes: StateChangeOptions<void>) => {
    if (changes.isOpen !== undefined) {
      setSuggestIsOpen(changes.isOpen)
    }
  }

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      setSuggestIsOpen(false)
    }
  }

  const stateReducer = (state: DownshiftState<void>, changes: StateChangeOptions<void>) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        if (typeof changes.inputValue === 'string') {
          setSearchQuery(changes.inputValue)
        }
        return {
          ...changes,
        }
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.mouseUp:
        return {
          ...changes,
          inputValue: state.inputValue,
        }
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        if (typeof changes.inputValue === 'string') {
          setSearchQuery(changes.inputValue)
        }
        return {
          ...changes,
        }
      default:
        return changes
    }
  }

  return (
    <Downshift
      isOpen={suggestIsOpen}
      inputValue={searchQuery}
      onStateChange={handleOnStateChange}
      stateReducer={stateReducer}
      itemToString={dot => (dot ? dot.strain : '')}>
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
      }) => (
        <Paper {...getRootProps()} className={classes.root} elevation={1}>
          <div className={classes.tools}>
            <IconButton aria-label={'Search'}>
              <SearchIcon fontSize={'inherit'} />
            </IconButton>
            <InputBase
              // margin={'dense'}
              className={classes.input}
              inputProps={{
                ...getInputProps({
                  'aria-label': 'Search Strains',
                  placeholder: 'Search Strains',
                  onKeyUp: handleKeyUp,
                }),
              }}
            />

            {searchQuery ? (
              <IconButton
                disableFocusRipple
                disableTouchRipple
                className={classnames(classes.iconButton, classes.clearInputButton)}
                onClick={handleClearSearch}
                size={'small'}
                aria-label={'Clear Search'}>
                <CancelIcon fontSize={'inherit'} />
              </IconButton>
            ) : null}
            <Divider className={classes.divider} />
            <IconButton
              disableFocusRipple
              disableTouchRipple
              className={classnames(classes.iconButton, classes.closeDrawerButton)}
              onClick={handleCloseSearchDrawer}
              aria-label={'Close'}>
              <KeyboardArrowLeft fontSize={'inherit'} />
            </IconButton>
          </div>
          <List {...getMenuProps()} className={classes.suggestions}>
            {isOpen &&
              resolvedDots
                .filter(
                  dot =>
                    !inputValue ||
                    dot.strain
                      .trim()
                      .toLowerCase()
                      .includes(inputValue.trim().toLowerCase())
                )
                .slice(0, 5)
                .map((item, index) => (
                  <ListItem
                    dense
                    {...getItemProps({
                      key: item.id,
                      index,
                      item,
                      style: {
                        backgroundColor: highlightedIndex === index ? 'lightgray' : undefined,
                      },
                      className: classes.suggestionItem,
                    })}>
                    <StrainLegendIcon
                      className={classes.suggestionIcon}
                      size={14}
                      color={item.color || '#ccc'}
                    />
                    <ListItemText primary={item.strain} />
                  </ListItem>
                ))}
          </List>
        </Paper>
      )}
    </Downshift>
  )
}

export default SearchBar
