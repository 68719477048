/* eslint-disable */
declare const analytics: any

export default class CCAnalytics {
  public static track(event: string, properties?: Record<string, any>) {
    // @ts-ignore
    if (window.analytics && analytics) {
      analytics.track(event, properties)
    }
  }
}
