import React, { useContext, useMemo } from 'react'

export const EnvContext = React.createContext({
  badgeBaseUrl: 'https://ds-connect-badges.s3-us-west-2.amazonaws.com/high/',
  wholesaleBaseUrl: 'https://wholesale.confidentcannabis.com/',
})

export const useBadgeUrl = (token: string) => {
  const { badgeBaseUrl } = useContext(EnvContext)
  return useMemo(() => `${badgeBaseUrl}${token}.png`, [token])
}

export const useWholesaleUrl = (token: string) => {
  const { wholesaleBaseUrl } = useContext(EnvContext)
  return useMemo(() => `${wholesaleBaseUrl}${token}`, [token])
}
