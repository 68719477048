import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import StrainLegendIcon from './StrainLegendIcon'
import { Theme } from '../theme/theme'

const useStyles = makeStyles((theme: Theme) => ({
  legendIcon: {
    margin: theme.spacing(0, 2, 0, 0),
  },
}))

export interface LegendItem {
  label: string
  color: string
}

interface Props {
  className?: string
  contents: LegendItem[]
}

const Legend: React.FC<Props> = ({ contents }) => {
  const classes = useStyles()

  return (
    <List dense>
      {contents &&
        contents.map((each: LegendItem) => {
          return (
            <ListItem key={each.label}>
              <StrainLegendIcon className={classes.legendIcon} size={16} color={each.color} />
              <ListItemText primary={each.label} />
            </ListItem>
          )
        })}
    </List>
  )
}

export default Legend
