import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Collapse from '@material-ui/core/Collapse'
import { CSSProperties } from '@material-ui/styles'
import classnames from 'classnames'
import { TableRow, TableBody, TableCell, Table } from '@material-ui/core'
import { Theme } from '../theme/theme'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 320,
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  header: {
    padding: theme.spacing(1, 1, 1, 2),
    userSelect: 'none',
    height: 40,
    backgroundColor: theme.branding.greyPalette.medium,
  },
  title: {
    margin: theme.spacing(1, 0, 2, 0),
    lineHeight: '26px',
  },
  subheader: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  media: {
    height: 0,
    paddingTop: '100%',
  },
  cardContent: {
    padding: theme.spacing(0.5),
  },
  bold: {
    fontWeight: 800,
    letterSpacing: '0.08rem',
  },
  white: {
    color: theme.palette.common.white,
  },
  closeIcon: {
    margin: theme.spacing(1),
  },
  idNumber: {
    left: '2px',
    bottom: '2px',
    fontSize: '8px',
    color: '#999',
  },
  globeIcon: {
    margin: theme.spacing(0, 1),
  },
  hide: {
    display: 'none',
  },
}))

export interface RegionCardProps {
  style?: CSSProperties
  handleClose: () => void
  id: number
  className?: string
  badgeUrl: string
  headerColor?: string
  cannabinoidClasses?: string
  classification?: string
  mostCommonPrivateStrains?: string
  bestKnownGlobalStrains?: string
  mostCentralNationalStrain: string
}

const RegionCard: React.FC<RegionCardProps> = ({
  handleClose,
  cannabinoidClasses,
  classification,
  mostCommonPrivateStrains,
  bestKnownGlobalStrains,
  mostCentralNationalStrain,
  badgeUrl,
  className,
  style,
}) => {
  const classes = useStyles()
  const [mobileExpandCardOpen, setMobileExpandCardOpen] = useState(false)
  const maxWidth600 = useMediaQuery('(max-width:600px)')

  return (
    <Card style={style} className={classnames(classes.card, className)}>
      <CardHeader
        className={classes.header}
        titleTypographyProps={{
          variant: 'h6',
          className: classnames(classes.white, classes.title),
        }}
        onClick={maxWidth600 ? () => setMobileExpandCardOpen(!mobileExpandCardOpen) : undefined}
        action={
          <IconButton onClick={handleClose} className={classes.closeIcon} size={'small'}>
            <CloseIcon className={classnames(classes.white)} fontSize={'inherit'} />
          </IconButton>
        }
      />

      <CardContent className={classes.cardContent}>
        {maxWidth600 ? (
          <Collapse in={maxWidth600 && mobileExpandCardOpen}>
            <CardMedia className={classnames(classes.media)} image={badgeUrl} title={'Imprint'} />
          </Collapse>
        ) : (
          <CardMedia className={classnames(classes.media)} image={badgeUrl} title={'Imprint'} />
        )}
        <Table>
          <TableBody>
            {cannabinoidClasses && (
              <TableRow>
                <TableCell> Cannabinoid Classes</TableCell>
                <TableCell>{cannabinoidClasses}</TableCell>
              </TableRow>
            )}

            {classification && (
              <TableRow>
                <TableCell> Classifications</TableCell>
                <TableCell>{classification}</TableCell>
              </TableRow>
            )}
            {mostCommonPrivateStrains && (
              <TableRow>
                <TableCell> Most Common Private Dots</TableCell>
                <TableCell>{mostCommonPrivateStrains}</TableCell>
              </TableRow>
            )}
            {bestKnownGlobalStrains && (
              <TableRow>
                <TableCell> Best Known Composites</TableCell>
                <TableCell>{bestKnownGlobalStrains}</TableCell>
              </TableRow>
            )}
            {mostCentralNationalStrain && (
              <TableRow>
                <TableCell> Representative Composite</TableCell>
                <TableCell>{mostCentralNationalStrain}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default RegionCard
