import React, { ReactNode } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard } from 'react-swipeable-views-utils'

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews)

interface SwipeableCardsProps {
  children: ReactNode[]
  index: number
  containerStyle: object
  slideStyle: object
  animate: boolean
  onChangeIndex: (index: number, indexLatest: number, meta?: object) => void
}

const SwipeableCards: React.FC<SwipeableCardsProps> = ({
  children,
  index,
  onChangeIndex,
  slideStyle,
  containerStyle,
  animate,
}) => {
  return (
    <BindKeyboardSwipeableViews
      style={containerStyle}
      slideStyle={slideStyle}
      animateTransitions={animate}
      enableMouseEvents={animate}
      index={index}
      onChangeIndex={onChangeIndex}
      resistance>
      {children}
    </BindKeyboardSwipeableViews>
  )
}

export default SwipeableCards
