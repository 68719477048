import React, { MouseEvent, ReactNode } from 'react'
import Menu from '@material-ui/core/Menu'
import CCMenuItem from './CCMenuItem'

type ColorOption = 'c' | 'ish' | 'method'

interface Props {
  className?: string
  colorOption: ColorOption
  setColorOption: (str: 'c' | 'ish' | 'method') => void
  anchorEl: ReactNode
  setAnchorEl: (el: ReactNode) => void
}

const ColorOptionMenu: React.FC<Props> = ({ anchorEl, setAnchorEl, setColorOption }) => {
  const handleClose = () => setAnchorEl(null)
  const handleClick = (e: MouseEvent, value?: string) => {
    setColorOption(value as ColorOption)
    handleClose()
  }

  const colorOptions = [
    {
      value: 'c',
      label: 'Cannabinoid',
    },
    {
      value: 'ish',
      label: 'Indica/Sativa',
    },
    {
      value: 'method',
      label: 'Method',
    },
  ]

  return (
    <Menu
      onBackdropClick={handleClose}
      id={'color-option-menu'}
      anchorEl={anchorEl as Element}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      variant={'selectedMenu'}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      {colorOptions.map(option => {
        return (
          <CCMenuItem
            key={option.label}
            onClick={handleClick}
            label={option.label}
            value={option.value}
          />
        )
      })}
    </Menu>
  )
}

export default ColorOptionMenu
