import React, { ChangeEvent, useState, useEffect } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import { Theme } from '../theme/theme'
import CollapsableFilter from './CollapsableFilter'

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1, 2, 1, 4),
    display: 'flex',
    flexDirection: 'column',
  },
  filtersHeader: {
    height: '24px',
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  defaultBtn: {
    color: theme.branding.greyPalette.soft,
  },
  formList: {
    height: theme.spacing(4),
  },
  white: {
    color: theme.palette.common.white,
  },
  softBlack: {
    color: theme.branding.greyPalette.soft,
  },
  bold: {
    fontWeight: 800,
    letterSpacing: '0.08rem',
  },
  formLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
}))

// HALP
type FilterCategory = 'other' | 'taxonomy' | 'method' | 'locales'

interface Filter {
  value: boolean
  label: string
}

export interface FiltersState {
  other: Record<string, Filter>
  taxonomy: Record<string, Filter>
  method: Record<string, Filter>
  locales: Record<string, Filter>
}

export interface FilterProps {
  filters: FiltersState
  setFilters: (filters: FilterProps['filters']) => void
}

const Filters: React.FC<FilterProps> = ({ filters, setFilters }) => {
  const classes = useStyles()

  const [expandedPanel, setExpandedPanel] = useState()
  const [isDefaultFilter, setIsDefaultFilter] = useState(true)

  const getDefaultFilters = () => {
    const defaultFilters = _.extend({}, filters)
    defaultFilters.other.composite.value = true
    _.forEach(
      ['other', 'taxonomy', 'method', 'locales'] as FilterCategory[],
      (category: FilterCategory) => {
        if (category === 'other') {
          _.forEach(defaultFilters[category], filter => {
            filter.value = true
          })
        } else {
          _.forEach(defaultFilters[category], (filter, key) => {
            if (key === 'all') {
              filter.value = true
            } else {
              filter.value = false
            }
          })
        }
      }
    )
    return defaultFilters
  }

  const setDefaultFilters = () => {
    setFilters(getDefaultFilters())
    setIsDefaultFilter(true)
  }

  const handleIsDefaultFilter = () => {
    let result = true
    if (!filters.other.composite.value) {
      result = false
    } else if (filters.method && !filters.method.all.value) {
      result = false
    } else if (filters.taxonomy && !filters.taxonomy.all.value) {
      result = false
    } else if (filters.locales && !filters.locales.all.value) {
      result = false
    }
    return result
  }

  useEffect(() => {
    if (!handleIsDefaultFilter()) {
      setIsDefaultFilter(false)
    } else {
      setIsDefaultFilter(true)
    }
  }, [filters])

  const handleExpand = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedFilters = _.extend({}, filters)
    const filter = e.target.value
    updatedFilters.other[filter].value = checked
    setFilters(updatedFilters)
  }

  const handleMultiFilter = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    category: FilterCategory
  ) => {
    const updatedFilters = _.extend({}, filters)
    const targetFilter = e.currentTarget.value
    if (targetFilter === 'all') {
      _.forEach(updatedFilters[category], (each, key) => {
        if (key !== targetFilter && typeof each !== 'boolean' && typeof each !== 'string') {
          each.value = false
        }
      })
    } else if (updatedFilters[category].all.value) {
      updatedFilters[category].all.value = false
    }
    updatedFilters[category][targetFilter].value = checked
    setFilters(updatedFilters)
    if (!handleIsDefaultFilter()) {
      setIsDefaultFilter(false)
    }
  }

  return (
    <div>
      <Container className={classes.filters}>
        <Container className={classes.filtersHeader}>
          <Typography variant={'subtitle1'} className={classnames(classes.softBlack, classes.bold)}>
            Filters
          </Typography>
          {!isDefaultFilter ? (
            <Button classes={{ text: classes.defaultBtn }} onClick={setDefaultFilters}>
              Reset
            </Button>
          ) : null}
        </Container>
        <FormControl margin={'dense'}>
          <FormGroup>
            <FormControlLabel
              classes={{ label: classnames(classes.formLabel, classes.softBlack) }}
              label={filters.other.composite.label}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize={'small'} />}
                  checkedIcon={<CheckBoxIcon fontSize={'small'} />}
                  checked={filters.other.composite.value}
                  onChange={handleChange}
                  value={'composite'}
                  color={'secondary'}
                />
              }
            />
          </FormGroup>
        </FormControl>
      </Container>
      {filters.locales ? (
        <CollapsableFilter
          expanded={expandedPanel === 'locales'}
          handleExpand={handleExpand('locales')}
          filters={filters.locales}
          label={'Location'}
          handleChange={(e, checked) => handleMultiFilter(e, checked, 'locales')}
        />
      ) : null}
      {filters.taxonomy ? (
        <CollapsableFilter
          expanded={expandedPanel === 'taxonomy'}
          handleExpand={handleExpand('taxonomy')}
          filters={filters.taxonomy}
          label={'Classification'}
          handleChange={(e, checked) => handleMultiFilter(e, checked, 'taxonomy')}
        />
      ) : null}
      {filters.method ? (
        <CollapsableFilter
          expanded={expandedPanel === 'method'}
          handleExpand={handleExpand('method')}
          filters={filters.method}
          label={'Method'}
          handleChange={(e, checked) => handleMultiFilter(e, checked, 'method')}
        />
      ) : null}
      <Divider />
    </div>
  )
}

export default Filters
