import React, { ReactNode, useEffect } from 'react'
import EventListener from 'react-event-listener'
import { useSprings, animated, interpolate } from 'react-spring'
import { StrainCardProps } from './StrainCard'

const stack = (i: number) => ({
  opacity: i > 2 ? 0 : 1,
  x: 0,
  y: i * 16,
  zIndex: 10 - i,
  scale: 1 - i / 28,
  immediate: (n: string) => n === 'zIndex',
})
const offScreen = () => ({ x: 500, zIndex: 20, immediate: (n: string) => n === 'zIndex' })

export interface CardStackProps {
  cardIndex: number
  onChange: (index: number, indexLatest: number) => void
  children: ReactNode[]
}

const CardStack: React.FC<CardStackProps> = ({ cardIndex, onChange, children }) => {
  const [springs, setSprings] = useSprings(children.length, i => ({ ...stack(i) }))

  useEffect(() => {
    // @ts-ignore
    setSprings((i: number) => {
      if (i >= cardIndex) {
        const diff = i - cardIndex
        return stack(diff)
      }
      return offScreen()
    })
  }, [cardIndex])

  const handleClick = (dir: string) => {
    let newIndex = 0
    if (dir === 'right') {
      newIndex = cardIndex + 1
    } else {
      newIndex = cardIndex - 1
    }
    onChange(newIndex, cardIndex)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.keyCode) {
      // left arrow	37
      // down arrow	40
      case 37:
      case 40:
        if (cardIndex > 0) {
          handleClick('left')
        }
        break

      // up arrow	38
      // right arrow	39
      case 38:
      case 39:
        if (cardIndex < children.length - 1) {
          handleClick('right')
        }
        break

      default:
        break
    }
  }

  return (
    <EventListener target={'window'} onKeyDown={handleKeyDown}>
      <div
        style={{
          paddingLeft: '8px',
          height: '512px',
          width: '336px',
          overflow: 'hidden',
          position: 'relative',
        }}>
        {springs.map(({ x, y, scale, zIndex, opacity }, i) => {
          return (
            <animated.div
              // eslint-disable-next-line react/no-array-index-key
              key={`${i}`}
              style={{
                opacity,
                position: 'absolute',
                zIndex,
                width: 320,
                transform: interpolate(
                  [x, y, scale],
                  (ix, iy, iscale) => `translate3d(${ix}px,${iy}px,0) scale(${iscale})`
                ),
              }}>
              {React.isValidElement(children[i]) &&
                React.cloneElement(children[i] as React.ReactElement<StrainCardProps>, {
                  showStackButtons: children.length > 1,
                  disableLeft: cardIndex === 0,
                  disableRight: cardIndex === children.length - 1,
                  changeCardStack: handleClick,
                })}
            </animated.div>
          )
        })}
      </div>
    </EventListener>
  )
}

export default CardStack
